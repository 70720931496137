import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChatMode, Model } from 'app/api/models/conversation';
import { BaseComponent } from 'app/base.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';

const MODELS: Model[] = [{
    id: 'gpt-35-turbo',
    name: 'GPT-3.5',
}, {
    id: 'gpt-35-turbo-16k',
    name: 'GPT-3.5-16K',
}];

@Component({
    selector: 'app-chat-dinootoo-header',
    templateUrl: './chat-dinootoo-header.component.html',
    styleUrls: ['./chat-dinootoo-header.component.scss'],
})
export default class ChatDinootooHeaderComponent extends BaseComponent {
    public modal: NgbModalRef;

    // Retourne l'url de la page d'aide en fonction de la langue
    get helpPageUrl() {
        if (this.translate.currentLang === 'en') {
            return 'help-en/books/dinootoo-in-visual-crm';
        }
        return 'help/books/dinootoo-dans-visual-crm';
    }

    get isConversationMode() {
        return this.chatService.chatMode.getValue() === ChatMode.CONVERSATION;
    }

    get conversation() {
        return this.chatService.conversation.getValue();
    }

    get mode() {
        return this.chatService.chatMode.getValue();
    }

    get discoverySummary() {
        return this.chatService.interviewSummary.getValue();
    }

    constructor(
        public modalService: NgbModal,
        private translate: TranslateService,
        public chatService: ChatService,
    ) {
        super();
    }

    /**
     * Retourne l'objet model en fonction de l'ID demandé
     * @param modelId : id du model recherché
     */
    // eslint-disable-next-line class-methods-use-this
    public getModelById(modelId: string): Model {
        return MODELS.find((model) => model.id === modelId);
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Nouvelle conversation" ou "Nouvelle configuration"
     */
    public async onConfirmNew() {
        this.chatService.startNewChatDinootoo.emit();
        this.modal.close();
    }

    /**
     * Evènement lorsque l'on ferme le chat
     */
    public onCloseChat() {
        this.chatService.isChatDisplay = false;
    }
}
