import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import {
    ChatMode, Conversation, ConversationStatus, Role,
} from 'app/api/models/conversation';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { firstValueFrom } from 'rxjs';
import MarkdownIt from 'markdown-it';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { ExplorationLLM } from 'app/api/models/llm';
import { Store } from '@ngxs/store';
import { SetIsDiscoveryInProgress } from 'app/stores/actions/settings/settings.actions';

const MODEL_ID = 'gpt-35-turbo-16k';
const TEMPERATURE = 0.2;

@Component({
    selector: 'app-chat-dinootoo-discovery',
    templateUrl: './chat-dinootoo-discovery.component.html',
    styleUrls: ['./chat-dinootoo-discovery.component.scss'],
})
export default class ChatDinootooDiscoveryComponent extends BaseComponent implements OnInit {
    public currentDashboardId: number;

    public md: MarkdownIt;

    public currentDiscovery: Conversation;

    public summaryText: string;

    public discoveryName = '';

    public editSummary = false;

    public showDiscoveryName = false;

    public showErrorOnIaConfigRequest = false;

    public showFinishMessage = false;

    public nbVerbatimOfDiscovery;

    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    @ViewChild('editedSummary')
        editedSummaryEl: ElementRef;

    get isDiscoveryMode() {
        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY;
    }

    get summaryTextWithMarkdown() {
        return this.md.render(this.summaryText);
    }

    get isDashInitial() {
        return this.manageDashboardService.currentDashboard?.getValue()?.dash_initial || false;
    }

    constructor(
        private store: Store,
        private dashboardApi: DashboardApi,
        public chatService: ChatService,
        public manageDashboardService: ManageDashboardService,
    ) {
        super();

        // Init MarkdownIt
        this.md = new MarkdownIt({
            html: false, // prevent html in markdown to prevent XSS
        });
        this.md.disable('image');
    }

    ngOnInit(): void {
        // Lorsque la configuration change, on met à jour la configuration courante
        this.subs.sink = this.chatService.interview.subscribe((interview) => {
            this.currentDiscovery = JSON.parse(JSON.stringify(interview));
        });

        // Lorsque l'on change le mode du chat et que l'on a sélectionné "Découverte"
        this.subs.sink = this.chatService.chatMode.subscribe((mode) => {
            if (mode === ChatMode.DISCOVERY && !this.chatService.interview.getValue()) {
                this.startNewDiscovery();
            }
        });

        // Lorsque l'on clique sur le bouton "Nouvelle conversation" (du header)
        this.subs.sink = this.chatService.startNewChatDinootoo.subscribe(() => {
            if (this.isDiscoveryMode) {
                this.startNewDiscovery();
            }
        });

        // Lorsque l'on envoie un message
        this.subs.sink = this.chatService.sendQuestionChatDinootoo.subscribe((question) => {
            if (question && this.isDiscoveryMode) {
                this.onSendQuestion(question);
            }
        });

        // Lorsque l'on recoit le résumé du LLM
        this.subs.sink = this.chatService.interviewSummary.subscribe((summary) => {
            if (summary) {
                this.summaryText = summary;
            }
        });

        // Lorsque l'on créé une nouvelle découverte (fin du processus)
        this.subs.sink = this.chatService.launchDiscovery.subscribe((discovery: ExplorationLLM) => {
            this.showFinishMessage = true;
            if (discovery) {
                this.nbVerbatimOfDiscovery = discovery.llm_nb_verbatim;
                this.chatService.scrollToBottom.emit();
            } else {
                // Si discovery est null, c'est qu'il y a eu une erreur sur l'appel à la création de la découverte
                this.showErrorOnIaConfigRequest = true;
                this.chatService.scrollToBottom.emit();
            }
        });

        // Lorsqu'un dashboard est chargé
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            // si le chat est déjà ouvert et que l'on charge un dashboard, alors il faut démarrer une nouvelle conversation
            if (this.chatService.isChatDisplay) {
                this.startNewDiscovery();
            }
        });
    }

    /**
     * Efface les anciens messages et démarre une nouvelle configuration
     */
    public async startNewDiscovery() {
        this.chatService.isLoadingAnswer = false;
        this.chatService.showValidDiscoveryBtn = false;
        this.chatService.showLaunchDiscoveryBtn = false;
        this.store.dispatch(new SetIsDiscoveryInProgress(false));
        this.showDiscoveryName = false;
        this.showFinishMessage = false;
        this.showErrorOnIaConfigRequest = false;
        this.chatService.interviewSummary.next(null);
        this.nbVerbatimOfDiscovery = undefined;

        // On démarre une nouvelle découverte si on n'est pas sur un dashboard initial
        if (!this.manageDashboardService.currentDashboard?.getValue()?.dash_initial) {
            // Enregistre l'ID du dashboard sur lequel on lance la conversation
            this.currentDashboardId = this.manageDashboardService.currentDashboard.getValue().dash_id;
            const discovery = {
                modelId: MODEL_ID,
                temperature: TEMPERATURE,
                messages: [],
                status: ConversationStatus.DIALOG,
                id: null,
            };
            // Démarre une nouvelle configuration
            const interview = await firstValueFrom(this.dashboardApi.createChatbotInterview(
                this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
                this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue(),
            ));
            discovery.id = interview.id;
            discovery.messages.push(interview.message);
            this.chatService.interview.next(discovery);
        }
    }

    /**
     * Evènement lorsque l'utilisateur pose une question
     * Envoi la requête à l'API
     */
    public async onSendQuestion(question: string) {
        gtmClick({ track_category: 'dinootoo', track_name: 'envoyer le message configuration' });
        this.store.dispatch(new SetIsDiscoveryInProgress(true));
        const interview = JSON.parse(JSON.stringify(this.chatService.interview.getValue()));

        interview.messages.push({
            role: Role.USER,
            content: question,
        });

        this.chatService.interview.next(interview);
        this.chatService.scrollToBottom.emit();

        interview.messages.push(await this.chatService.sendMessages(this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id, interview));

        this.chatService.interview.next(interview);
        this.chatService.scrollToBottom.emit();
    }

    /**
     * Evènement lorsque l'utilisateur souhaite modifier le résumé proposé par le LLM
     */
    public onCancelSummary() {
        this.summaryText = this.chatService.interviewSummary.getValue();
        this.editSummary = false;
    }

    /**
     * Evènement lorsque l'utilisateur valide le résumé de la discussion
     */
    public async onValidSummary() {
        this.editSummary = false;
        this.showDiscoveryName = false;
        this.chatService.isLoadingAnswer = true;
        this.chatService.interviewSummary.next(this.editedSummaryEl?.nativeElement.innerHTML || this.summaryText);
        if (this.chatService.interview.getValue().id) {
            try {
                this.chatService.interviewIaConfig.next(
                    await firstValueFrom(this.dashboardApi.postChatbotIaConfig(this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id, {
                        interview_id: this.chatService.interview.getValue().id,
                        configuration: this.chatService.interviewSummary.getValue(),
                        searchparams: this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue(),
                    })),
                );
                this.showDiscoveryName = true;
                this.discoveryName = this.chatService.interviewIaConfig.getValue().config_name;
            } catch (e) {
                this.showErrorOnIaConfigRequest = true;
            } finally {
                this.chatService.isLoadingAnswer = false;
                this.chatService.scrollToBottom.emit();
            }
        }
    }

    /**
     * Evènement lorsque l'utilisateur enregistre le nom de la configuration
     */
    public async onSaveDiscoveryName() {
        // Si le nom a été modifié, on le met à jour cote backend
        if (this.discoveryName !== this.chatService.interviewIaConfig.getValue()?.config_name) {
            try {
                await firstValueFrom(this.dashboardApi.patchChatbotIaConfig(
                    this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
                    this.chatService.interviewIaConfig.getValue().config_id,
                    this.discoveryName,
                ));
                this.chatService.interview.next({ ...this.chatService.interview.getValue(), status: ConversationStatus.ENDED });
                this.chatService.showLaunchDiscoveryBtn = true;
            } catch (e) {
                this.showErrorOnIaConfigRequest = true;
            }
        } else {
            this.chatService.interview.next({ ...this.chatService.interview.getValue(), status: ConversationStatus.ENDED });
            this.chatService.showLaunchDiscoveryBtn = true;
        }
        this.chatService.scrollToBottom.emit();
    }
}
