import { BaseComponent } from 'app/base.component';

/* eslint-disable class-methods-use-this */
export default class BaseEtapeComponent extends BaseComponent {
    isEtape1Invalid(state): boolean {
        return !state.datas || !state.datas.name || state.datas.name === '';
    }

    isEtape2Invalid(state): boolean {
        return !state.datas.file
            || (state.datas.file.corpusType === 3
            && !state.datas.file.name)
            || (state.datas.file.corpusType === 1
            && (!state.datas.file
                || !state.datas.file.name
                || !state.datas.file.encoding
                || !state.datas.file.delimiter));
    }

    isEtape3Invalid(state): boolean {
        // IB inversion message et date
        return (state.datas.file === 1 && !((state.datas.noColDate && state.datas.dateInit) || (state.datas.date && state.datas.dateFormat)));
    }

    isEtape4Invalid(state): boolean {
        // IB inversion message et date
        return !state.datas.verbatim || !state.datas.language;
    }

    isEtape5Invalid(state): boolean {
        return !state.datas.id;
    }
}
