import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { ChatMode } from 'app/api/models/conversation';
import { BaseComponent } from 'app/base.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';

@Component({
    selector: 'app-chat-dinootoo',
    templateUrl: './chat-dinootoo.component.html',
    styleUrls: ['./chat-dinootoo.component.scss'],
})
export default class ChatDinootooComponent extends BaseComponent implements OnInit {
    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    // Détermine si on affiche plus de place entre la discussion et le prompt
    get isMoreSpaceForPromptAction() {
        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY && (this.chatService.showValidDiscoveryBtn || this.chatService.showLaunchDiscoveryBtn);
    }

    constructor(
        public chatService: ChatService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.chatService.scrollToBottom.subscribe(() => {
            this.scrollToBottom();
        });
    }

    /**
     * Scrolle tout en bas du chat
     */
    public scrollToBottom() {
        setTimeout(() => {
            this.chatContainerBody.nativeElement.scrollTo({
                top: this.chatContainerBody.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
        }, 0);
    }

    /**
     * Evènement lorsque l'on change le mode du chat (conversation ou découverte)
     */
    public onToggleMode(mode: ChatMode) {
        gtmClick({
            track_category: 'dinootoo',
            track_name: 'mode dinootoo découverte IA',
            track_cible: mode === ChatMode.CONVERSATION ? 'dinootoo' : 'découverte IA',
        });
        this.chatService.chatMode.next(mode);
        this.scrollToBottom();
    }
}
