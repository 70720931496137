import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ChatMode, Conversation, NbVerbatim, Role,
} from 'app/api/models/conversation';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { firstValueFrom } from 'rxjs';
import MarkdownIt from 'markdown-it';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ChatService from 'app/modules/corpus/corpus-chat.service';

const MODEL_ID = 'gpt-35-turbo-16k';
const TEMPERATURE = 0.2;
const CONTEXT = 20;

@Component({
    selector: 'app-chat-dinootoo-conversation',
    templateUrl: './chat-dinootoo-conversation.component.html',
    styleUrls: ['./chat-dinootoo-conversation.component.scss'],
})
export default class ChatDinootooConversationComponent extends BaseComponent implements OnInit {
    public modal: NgbModalRef;

    public showToaster: boolean = false;

    public nbVerbatim: number = 0;

    public removeMessageIndex: number;

    public currentDashboardId: number;

    public firstNbVerbatim: number;

    public firstTotalVerbatim: number;

    public md: MarkdownIt;

    public currentConversation: Conversation;

    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    // Retourne les X derniers messages de type 'user' ou 'assitant' qui ne contiennent pas d'erreur
    get contextMessage() {
        return this.chatService.conversation.getValue().messages.filter((m) => !m.error && [Role.USER, Role.ASSISTANT].includes(m.role)).slice(-20);
    }

    get isConversationMode() {
        return this.chatService.chatMode.getValue() === ChatMode.CONVERSATION;
    }

    constructor(
        public modalService: NgbModal,
        public chatService: ChatService,
        private dashboardApi: DashboardApi,
        public manageDashboardService: ManageDashboardService,
    ) {
        super();

        // Init MarkdownIt
        this.md = new MarkdownIt({
            html: false, // prevent html in markdown to prevent XSS
        });
        this.md.disable('image');
    }

    ngOnInit(): void {
        // Lorsque la conversation change, on met à jour la conversation courante
        this.subs.sink = this.chatService.conversation.subscribe((conversation) => {
            this.currentConversation = JSON.parse(JSON.stringify(conversation));
        });

        // Lorsque l'on ouvre le chat
        this.subs.sink = this.chatService.openChatDinootoo.subscribe(() => {
            // Si l'ID du dashboard sur lequel on a lancé la dernière conversation et différent de l'ID du dashboard courante, on lance une nouvelle conversation
            if (this.currentDashboardId !== this.manageDashboardService.currentDashboard.getValue().dash_id) {
                this.startNewConversation();
            }
        });

        // Lorsque l'on clique sur le bouton "Nouvelle conversation" (du header)
        this.subs.sink = this.chatService.startNewChatDinootoo.subscribe(() => {
            if (this.isConversationMode) {
                this.startNewConversation();
                this.chatService.isLoadingAnswer = false;
            }
        });

        // Lorsque l'on envoie un message
        this.subs.sink = this.chatService.sendQuestionChatDinootoo.subscribe((question) => {
            if (question && this.isConversationMode) {
                this.onSendQuestion(question);
            }
        });

        // Lorsqu'un dashboard est chargé
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            // si le chat est déjà ouvert et que l'on charge un dashboard, alors il faut démarrer une nouvelle conversation
            if (this.chatService.isChatDisplay) {
                await this.startNewConversation();
            }
        });

        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(async () => {
            // Met à jour le nombre de verbatim
            this.nbVerbatim = (await this.getNbVerbatim('update', this.currentConversation.id)).nb_verbatim;
            // Si aucun message n'a été échangé, on met à juste à jour le nombre de verbatim dans la 2ème bulle d'intro
            if (this.currentConversation.messages.length === 0) {
                this.firstNbVerbatim = this.nbVerbatim;
                this.firstTotalVerbatim = this.manageDashboardService.firstDashboardService?.dashboardData.getValue()?.hits.total;
            } else {
                // Sinon on affiche un message d'avertissement de changement de filtre
                // Si le dernier message était déjà un message de modification de filtres, on supprime l'ancien
                if (this.currentConversation.messages[this.currentConversation.messages.length - 1].role === Role.NEW_FILTER) {
                    this.currentConversation.messages.splice(-1);
                }
                const isNbVerbatimRandom = this.nbVerbatim !== this.manageDashboardService.firstDashboardService.dashboardData.getValue()?.hits.total;
                this.currentConversation.messages.push({
                    role: Role.NEW_FILTER,
                    content: `translations.analysisDashboard.chatDinootoo.chatbot.conversation.newFilter.content.${isNbVerbatimRandom ? 'random' : 'fixe'}`,
                    detail: {
                        nbVerbatim: this.nbVerbatim,
                        isNbVerbatimRandom,
                    },
                });
                this.chatService.scrollToBottom.emit();
            }
        });
    }

    /**
     * Create or update conversation
     */
    private getNbVerbatim(mode: 'create' | 'update', conversationId?: string): Promise<NbVerbatim> {
        return firstValueFrom(this.dashboardApi.createOrUpdateConversation(
            mode,
            this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
            conversationId,
            this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue(),
        ));
    }

    /**
     * Efface les anciens messages et démarre une nouvelle conversation
     */
    public async startNewConversation() {
        // Enregistre l'ID du dashboard sur lequel on lance la conversation
        this.currentDashboardId = this.manageDashboardService.currentDashboard.getValue().dash_id;
        // Démarre une nouvelle conversation
        const conversation = {
            modelId: MODEL_ID,
            temperature: TEMPERATURE,
            context: CONTEXT,
            messages: [],
            id: null,
        };
        const result = await this.getNbVerbatim('create');
        conversation.id = result.id;
        this.chatService.conversation.next(conversation);
        // Récupère l'ID de la conversation et le nombre de verbatim
        this.nbVerbatim = result.nb_verbatim;
        this.firstNbVerbatim = this.nbVerbatim;
        this.firstTotalVerbatim = this.manageDashboardService.firstDashboardService?.dashboardData.getValue()?.hits.total;
    }

    /**
     * Evènement lorsque l'utilisateur pose une question
     * Envoi la requête à l'API
     */
    public async onSendQuestion(question: string) {
        gtmClick({ track_category: 'dinootoo', track_name: 'envoyer le message' });
        const conversation = JSON.parse(JSON.stringify(this.chatService.conversation.getValue()));

        conversation.messages.push({
            role: Role.USER,
            content: question,
        });

        this.chatService.conversation.next(conversation);
        this.chatService.scrollToBottom.emit();

        const conversationToSend = JSON.parse(JSON.stringify(conversation));
        conversationToSend.messages = this.contextMessage;
        conversation.messages.push(await this.chatService.sendMessages(this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id, conversationToSend));

        this.chatService.conversation.next(conversation);
        this.chatService.scrollToBottom.emit();
    }

    /**
     * Evènement sur les boutons "delete" des messages
     * Supprime le couple question / réponse.
     */
    public onRemoveMessage() {
        // Supprime la question
        this.currentConversation.messages.splice(this.removeMessageIndex, 1);

        // Supprime la réponse (si elle existe)
        const nextMessage = this.currentConversation.messages[this.removeMessageIndex];
        if (nextMessage && nextMessage.role === Role.ASSISTANT) {
            this.currentConversation.messages.splice(this.removeMessageIndex, 1);
        }
        this.chatService.conversation.next(this.currentConversation);
        this.removeMessageIndex = undefined;
        this.modal.close();
    }

    /**
     * Evènement lorsque l'on clique sur les boutons "copy"
     * Copie dans le presse papier et affiche un message
     */
    public onCopyToClipboard(message: string) {
        this.showToaster = true;
        navigator.clipboard.writeText(message).finally(() => {
            setTimeout(() => {
                this.showToaster = false;
            }, 3000);
        });
    }
}
