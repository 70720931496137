import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Column, Inputnamevalues } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

@Component({
    selector: 'filters-associated-data-popup',
    templateUrl: './filters-associated-data-popup.component.html',
    styleUrls: ['./filters-associated-data-popup.component.scss'],
})
export default class FiltersAssociatedDataPopupComponent extends BaseComponent implements OnInit {
    @Output()
    private onSave: EventEmitter<boolean> = new EventEmitter();

    @Input()
    private titleToTranslate: string = 'translations.analysisDashboard.filter.select';

    @Input()
    private subtitleToTranslate: string = 'translations.analysisDashboard.filter.datas';

    public columns: Array<Column> = [];

    public applyFilterModalButtonDisabled: boolean = true;

    private suppressButtonDisabled: boolean = true;

    public collapses: Object = {};

    public numSubDatasToSelect: Object = {};

    public numSubDatasSelected: Object = {};

    public numSubDatasSelectedForMore100: Object = {};

    public allSubDatasSelected: Object = {};

    public noMore: boolean = false;

    public ArrayOfMore100ValuesSave: Array<number> = [];

    public displayColumns: Array<Column> = [];

    public currentPageArray: Array<number> = [];

    public currentPageArraySave: Array<number> = [];

    public ArrayOfBoolean: Array<Boolean> = [];

    public ArrayOfMore100Values: Array<number> = [];

    public makeColumns: Column;

    public columnsMore100Array: Array<string> = [];

    public placeholder: any;

    public modalFilters: Array<Inputnamevalues> = [];

    public activeFilters: Array<string> = [];

    private activeFiltersInitialState: Array<string> = [];

    public searchModel: string = '';

    public cleanSearchBoolean: boolean = false;

    public dashboardType: string;

    private title: string;

    private subtitle: string;

    // inputs
    private corpusApi;

    private dashboardService: DashboardService;

    private treatmentField: string = null;

    constructor(
        private store: Store,
        public modal: NgbActiveModal,
    ) {
        super();
    }

    get verbatimDBName() {
        return this.dashboardService.currentFilters.getValue().verbatim_dbname;
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentFilters.subscribe((params) => {
            if (params != null) {
                this.activeFilters = [];
                params.filters.forEach((f) => {
                    f.values.forEach((val) => {
                        this.activeFilters.push(`${f.inputName}|${val}`);
                    });
                });

                this.activeFiltersInitialState = { ...(this.activeFilters.sort()) };
            }
        });

        this.applyFilterModalButtonDisabled = true;
        this.noMore = false;
        this.columns = [];
        // --- Sauvegarde pour revenir à l'état initial quand on efface le recherche
        this.ArrayOfMore100ValuesSave = []; // this array fill in the same time that displayColumns has the same index and tell if the columns have more of 100 values or not
        // -----
        this.displayColumns = [];
        this.currentPageArray = []; // this array fill in the same time that displayColumns has the same index, and tell the currentPage of pagination
        this.ArrayOfBoolean = []; // this array fill in the same time that displayColumns has the same index and tell if the div of values should be show or not
        this.ArrayOfMore100Values = []; // this array fill in the same time that displayColumns has the same index and tell if the columns have more of 100 values or not
        this.makeColumns = {};
        this.columnsMore100Array = [];
        this.placeholder = 'translations.analysisDashboard.filter.search';
        this.modalFilters = _.cloneDeep(this.dashboardService.currentFilters.value.filters);
        this.dashboardType = this.dashboardService.currentDashboardType;

        this.subs.sink = this.dashboardService.currentVerbatimColumns.subscribe((cols) => {
            const columnstmp = cols as object;
            Object.keys(columnstmp).forEach((col) => {
                if ((this.treatmentField === null && !columnstmp[col].isTreatment) || (this.treatmentField !== null && col.startsWith(this.treatmentField))) {
                    const values = columnstmp[col].values.map((v) => v.key);
                    columnstmp[col].search = [columnstmp[col].displayName, values.join(' | ')].join(' | ');
                    this.columns.push(columnstmp[col]);
                    this.displayColumns.push(columnstmp[col]);
                    this.currentPageArray.push(1);
                    this.ArrayOfBoolean.push(false);
                    this.ArrayOfMore100Values.push(columnstmp[col].distinct_values_count);
                    if (columnstmp[col].distinct_values_count > 100) {
                        this.columnsMore100Array.push(columnstmp[col].inputName);
                    }
                }
            });
        });
        this.displayColumns.forEach((cols) => {
            this.numSubDatasToSelect[cols.inputName] = cols.values.length;
            this.numSubDatasSelected[cols.inputName] = 0;
            this.allSubDatasSelected[cols.inputName] = false;
            this.collapses[cols.inputName] = true;
        });
        this.currentPageArraySave = { ...(this.currentPageArray) };
        this.ArrayOfMore100ValuesSave = this.ArrayOfMore100Values;
        this.updateNumberOfDatasSelected();
    }

    onFilterChange(f, value, event): void {
        if (event.target.checked) {
            this.onFilterChangeByComp(f, value, true);
        } else this.onFilterChangeByComp(f, value, false);
    }

    onFilterChangeByComp(f, value, event): void {
        const selectedFilters = _.map(this.modalFilters, 'inputName');

        if (event) {
            if (_.includes(selectedFilters, f)) {
                if (!_.includes(this.modalFilters[selectedFilters.indexOf(f)].values, value)) {
                    this.modalFilters[selectedFilters.indexOf(f)].values.push(value);
                }
            } else {
                this.modalFilters.push({ values: [value], inputName: f });
            }
            if (!this.activeFilters.includes(`${f}|${value}`)) {
                this.activeFilters.push(`${f}|${value}`);
            }
        } else {
            this.modalFilters[selectedFilters.indexOf(f)].values.splice(this.modalFilters[selectedFilters.indexOf(f)].values.indexOf(value), 1);
            if (this.modalFilters[selectedFilters.indexOf(f)].values.length === 0) {
                this.modalFilters.splice(selectedFilters.indexOf(f), 1);
            }
            this.activeFilters = this.activeFilters.filter((v) => v !== `${f}|${value}`);
        }
        this.activeFilters.sort();
        this.updateNumberOfDatasSelected();
    }

    isFilterChecked(column, value):boolean {
        return this.activeFilters.indexOf(
            `${column.inputName}|${value.key}`,
        ) > -1;
    }

    toggleCollapse(input) {
        if (this.collapses[input] === undefined) {
            this.collapses[input] = true;
        }
        this.collapses[input] = !this.collapses[input];
    }

    updateNumberOfDatasSelected() {
        if (this.modalFilters.length === 1) {
            this.suppressButtonDisabled = true;
            this.displayColumns.forEach((cols) => {
                this.allSubDatasSelected[cols.inputName] = false;
                this.numSubDatasSelected[cols.inputName] = 0;
            });
            this.applyFilterModalButtonDisabled = _.isEmpty(diff(this.activeFilters, this.activeFiltersInitialState));
        } else {
            this.suppressButtonDisabled = false;
            this.modalFilters.forEach((cols) => {
                this.numSubDatasSelected[cols.inputName] = 0;
                cols.values.forEach(() => {
                    if (cols.values.length > 0) {
                        this.numSubDatasSelected[cols.inputName] += 1;
                    } else {
                        this.numSubDatasSelected[cols.inputName] = 0;
                    }
                });
                if (_.includes(this.columnsMore100Array, cols.inputName)) {
                    const colsForIndex = _.map(this.displayColumns, 'inputName');
                    this.updateNumberOfDatasSelectedForMore100(colsForIndex.indexOf(cols.inputName), cols.inputName);
                } else {
                    this.allSubDatasSelected[cols.inputName] = (this.numSubDatasSelected[cols.inputName] === this.numSubDatasToSelect[cols.inputName]);
                }
            });
            if (this.activeFilters.length > 201) {
                this.applyFilterModalButtonDisabled = true;
            } else {
                this.applyFilterModalButtonDisabled = _.isEmpty(diff(this.activeFilters, this.activeFiltersInitialState));
            }
        }
    }

    selectAllSubDatas(column, event) {
        if (event.target.checked) {
            this.displayColumns.forEach((cols) => {
                if (cols.inputName === column) {
                    cols.values.forEach((data) => {
                        this.onFilterChangeByComp(cols.inputName, data.key, true);
                    });
                }
            });
        } else {
            this.displayColumns.forEach((cols) => {
                if (cols.inputName === column) {
                    this.numSubDatasSelected[cols.inputName] = 0;
                    cols.values.forEach((data) => {
                        this.onFilterChangeByComp(cols.inputName, data.key, false);
                    });
                }
            });
        }
    }

    suppressAll() {
        this.modalFilters.splice(1); // efface tout sauf premier élément presverbatim
        const nbOfFilters = this.activeFilters.length;
        this.activeFilters.splice(0, (nbOfFilters - 1)); // efface tout sauf dernier élément presverbatim
        this.updateNumberOfDatasSelected();
    }

    modelSearch(e) {
        this.searchModel = e;
    }

    onSearchClean() {
        this.noMore = false;
        this.searchModel = '';
        this.cleanSearchBoolean = true;
        this.displayColumns.forEach((cols) => {
            this.collapses[cols.inputName] = true;
        });
    }

    noMoreOnSearch(e) {
        this.noMore = e;
    }

    onFreeSearch(e) {
        this.displayColumns = e;
        this.displayColumns.forEach((cols) => {
            this.collapses[cols.inputName] = false;
        });
        this.cleanSearchBoolean = false;
    }

    onMyPagination(e) {
        this.displayColumns = e;
    }

    onCurrentPageArrayOnSearch(e) {
        this.currentPageArray = e;
    }

    onNewCurrentPage(e) {
        let i = 0;
        let indexOfCol = 0;
        this.currentPageArray = e;
        this.currentPageArray.forEach((element) => {
            if (element !== this.currentPageArraySave[i]) {
                indexOfCol = i;
            } else i += 1;
        });
        this.currentPageArraySave = { ...(this.currentPageArray) };
        const colName = this.displayColumns[indexOfCol].inputName;
        this.updateNumberOfDatasSelectedForMore100(indexOfCol, colName);
    }

    updateNumberOfDatasSelectedForMore100(indexOfCol, colName) {
        if (this.modalFilters.length === 1) {
            this.suppressButtonDisabled = true;
            this.displayColumns.forEach((cols) => {
                this.allSubDatasSelected[cols.inputName] = false;
                this.numSubDatasSelected[cols.inputName] = 0;
                this.numSubDatasSelectedForMore100[colName] = 0;
            });
        } else {
            const selectedFilters = _.map(this.modalFilters, 'inputName');

            this.numSubDatasSelectedForMore100[colName] = 0;
            this.numSubDatasToSelect[colName] = this.displayColumns[indexOfCol].values.length;
            this.displayColumns[indexOfCol].values.forEach((value) => {
                if (_.includes(this.modalFilters[selectedFilters.indexOf(colName)].values, value.key)) { // si modalFilter contient la valeur alors la valeur est cochée
                    this.numSubDatasSelectedForMore100[colName] += 1;
                }
            });
            this.allSubDatasSelected[colName] = (this.numSubDatasSelectedForMore100[colName] === this.numSubDatasToSelect[colName]);
        }
    }

    onCollapsedOrNot(e) {
        this.ArrayOfBoolean = e;
    }

    onNewArrayOfBoolean(e) {
        this.ArrayOfBoolean = e;
    }

    onMore100valuesOnSearch(e) {
        this.ArrayOfMore100Values = e;
    }

    onSaveFilters() {
        this.dashboardService.updateFilterList(this.modalFilters);
        this.onSave.emit(true);
        this.modal.close();
    }
}
