/* eslint-disable no-underscore-dangle */
import {
    Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import sortArrayByProp from 'app/utils/functions/sortArrayByProp';
import * as moment from 'moment';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export default class TableComponent {
    @Input()
        columns: Array<any> = [];

    public asPagination: boolean = true;

    public itemPerPage: number = 10;

    public rowsRaw: Array<any> = [];

    public rowsSelected: Array<any> = [];

    @Input()
    public currentPropSort = null;

    @Input()
    public currentModeSort = false;

    @Input()
    public textNoData: string = null;

    @Input()
    public isRowSelectable: boolean = false;

    @Output() clickRowEvent = new EventEmitter<any>();

    @Output() sortColEvent = new EventEmitter<any>();

    private autoScroll: boolean = false;

    @ViewChild('scrollview')
        scrollview: ElementRef;

    private _rows: Array<any> = [];

    private _config: any = {};

    private _start = 0;

    private _end = 10;

    private allDetailsOpen: boolean = false;

    noHead: boolean;

    @Input()
    set config(config: any) {
        this._config = config;
        if (this.config.style === undefined) {
            this.config.style = { height: 'auto' };
        }

        if (this.config.itemPerPage !== undefined) {
            this.itemPerPage = this.config.itemPerPage;
            this._end = this.itemPerPage;
        } else {
            this.itemPerPage = 10;
            this._end = 10;
        }

        if (
            this.config.pagination === undefined
      || this.config.pagination === false
        ) {
            this.asPagination = false;
            this.itemPerPage = 0;
            this._end = 0;
        } else {
            this.asPagination = true;
        }

        if (this.config.noHead === undefined || this.config.noHead === false) {
            this.noHead = false;
        } else {
            this.noHead = true;
        }

        this.autoScroll = this.config.autoScroll;
    }

    get config() {
        return this._config;
    }

    isAllDetailsOpen() {
        return this.rows.every((row) => !row.isCollapsed);
    }

    toggleAllDetails() {
        this.rows.forEach((row) => { row.isCollapsed = this.allDetailsOpen; });
        this.allDetailsOpen = this.isAllDetailsOpen();
    }

    toggleRowDetails(row) {
        row.isCollapsed = !row.isCollapsed;
        this.allDetailsOpen = this.isAllDetailsOpen();
    }

    @Input()
    set rows(rows: Array<any>) {
        this.rowsRaw = rows;
        if (!this.config.externalSort) {
            this.sort(this.currentPropSort, this.currentModeSort);
        }
        if (this.asPagination) {
            this._rows = this.rowsRaw.slice(this._start, this._end);
        } else {
            this._rows = rows;
        }

        if (this.scrollview && this.autoScroll) {
            this.scrollview.nativeElement.scrollTop = 0;
        }
    }

    get rows(): Array<any> {
        if (!this.asPagination) {
            this._rows = this.rowsRaw;
        }
        return this._rows;
    }

    pageChanged(event: PageChangedEvent): void {
        this._start = (event.page - 1) * event.itemsPerPage;
        this._end = event.page * event.itemsPerPage;
        this.rows = this.rowsRaw;
    }

    sort(prop, changeMode = true) {
        this.currentPropSort = prop;
        this.columns.map((column) => {
            if (column.prop === prop) {
                if (changeMode) {
                    this.currentModeSort = !this.currentModeSort;
                    this.sortColEvent.emit({ order: this.currentModeSort, ...column });
                }
                if (!this.config.externalSort) {
                    switch (column.dataType) {
                        case 'number':
                            this._rows = this.numSort(prop, this.currentModeSort);
                            break;

                        case 'date':
                            this._rows = this.dateSort(
                                prop,
                                this.currentModeSort,
                                column.dataFormat,
                            );
                            break;

                        default:
                            this._rows = this.defaultSort(prop, this.currentModeSort);
                            break;
                    }
                }
            }
            return column;
        });
    }

    defaultSort(prop, mode) {
        if (!this.rowsRaw) {
            return;
        }
        // eslint-disable-next-line consistent-return
        return sortArrayByProp(this.rowsRaw, prop, mode);
    }

    numSort(prop, mode) {
        return this.rowsRaw.sort((rowA, rowB) => {
            const a = rowA;
            const b = rowB;
            if (a[prop] === undefined) {
                a[prop] = 0;
            }
            if (b[prop] === undefined) {
                b[prop] = 0;
            }
            if (mode) {
                return +a[prop] - +b[prop];
            } if (!mode) {
                return +b[prop] - +a[prop];
            }
            return 0;
        });
    }

    dateSort(prop, mode, format) {
        return this.rowsRaw.sort((rowA, rowB) => {
            const a = moment(rowA[prop] || '0', format);
            const b = moment(rowB[prop] || '0', format);

            if (a.diff(b) > 0) {
                if (mode) {
                    return -1;
                }
                return 1;
            } if (b.diff(a) > 0) {
                if (mode) {
                    return 1;
                }
                return -1;
            }

            return 0;
        });
    }

    /**
   * Determine if row is already selected or not
   */
    isRowSelected(row) {
        return this.isRowSelectable && this.rowsSelected.find((r) => r === row);
    }

    /**
   * Fired when user click on each TR table
   * @param row : row object who user click
   */
    clickRow(row) {
    // Determine if selected row is already selected array or not
        if (this.isRowSelected(row)) {
            // If it is, remove this
            this.rowsSelected = this.rowsSelected.filter((r) => r !== row);
        } else if (this.config?.selectMultipleRows) {
            // If config contain multiple row
            // Else add this row to selected array
            this.rowsSelected.push(row);
        } else {
            // Just return one selected row
            this.rowsSelected = [row];
        }
        // Fired event
        this.clickRowEvent.emit(this.rowsSelected);
    }
}
