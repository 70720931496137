/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import {

    ChangeDetectorRef,
    Component, ElementRef, OnInit, SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'app/base.component';
import ExportType from 'app/modules/CommonExportTypeEnum';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusState from 'app/stores/state/corpus/corpus.state';
import { DashboardConfig } from 'app/api/models/dashboard-config';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { firstValueFrom, Observable } from 'rxjs';
import CorpusService from 'app/utils/services/corpus.service';
import { VerbatimConfigField } from 'app/api/models/verbatimconfig';
import download from 'app/utils/functions/download';
import { Fieldname } from 'app/api/models/fieldname';
import { KeywordSearch } from 'app/api/models/keywordsdata';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { NgxPermissionsService } from 'ngx-permissions';
import {
    CorpusDataDownload, GetCorpus,
} from '../../../../../api/models';
import { CorpusService as CorpusApi, DashboardService as DashboardApi } from '../../../../../api/services';
import { CloudData } from '../words/word-cloud.interfaces';
import analytics from '../words/words.analytics';
import { Searchdata } from '../../../../../api/models/searchdata';
import { SearchField, SearchFieldType, SearchSortOrder } from '../../../../../api/models/searchparams';
import AnnotationsModalComponent from './annotations-modal/annotations-modal.component';

@Component({
    selector: 'app-verbatim-card',
    templateUrl: './verbatim-card.component.html',
    styleUrls: ['./verbatim-card.component.scss'],
})
export default class VerbatimCardComponent extends BaseComponent implements OnInit {
    public corpusColumns: Array<SearchField> = [];

    private saveSortedColumn;

    public saveLastSeletedColumn: Array<SearchField> = [];

    get searchColumns() {
        return this.corpusColumns.filter((column) => column.displayName.toLowerCase().includes(this.modalSearchColumns.toLocaleLowerCase()));
    }

    get associatedColumns() {
        return this.searchColumns.filter((column) => [SearchFieldType.DATE, SearchFieldType.COLUMN].includes(column.type));
    }

    get treatmentColumns() {
        return this.searchColumns.filter((column) => column.type === SearchFieldType.TREATMENT);
    }

    get classColumns() {
        return this.searchColumns.filter((column) => column.type === SearchFieldType.CLASS);
    }

    get verbatimClassColumns() {
        const columns = this.classColumns.find((column) => column.DBName === this.dashboardService.lastAppliedFilters.getValue().verbatim_dbname);
        return columns ? [columns] : [];
    }

    get selectedColumns() {
        return this.corpusColumns.filter((column) => column.checked);
    }

    get isSelectedColumnUpdate() {
        return this.saveLastSeletedColumn.map((col) => col.DBName).join(',') !== this.selectedColumns.map((col) => col.DBName).join(',');
    }

    words: any[] = [];

    public verbatims = [];

    public columns: Array<object> = [];

    public config: any;

    public maxPageSize: number = 100;

    private exportType = ExportType;

    private selectedType: ExportType;

    dashboardConfig: DashboardConfig;

    search: string = '';

    public total: number;

    protected currentPage: number;

    currentCorpusData: Searchdata;

    modalRef: BsModalRef;

    category: string;

    pageCount: number = 0;

    public modalSearchColumns: string = '';

    public isAccordionOpen: Array<boolean> = [true, true, true, true, true];

    private columnDate: SearchField;

    public pertIsDisabled = true;

    @ViewChild('classesTpl', { read: TemplateRef }) classesTpl;

    @ViewChild('annotationsRowTpl')
        annotationsRowTpl: TemplateRef<any>;

    @ViewChild('favoriteRowTpl')
        favoriteRowTpl: TemplateRef<any>;

    @ViewChild('saveAsBeforeFavorite')
    private saveAsBeforeFavorite: TemplateRef<any>;

    saveName: string = '';

    selectedFavId;

    get hasData() {
        return this.dashboardService.dashboardData.getValue()?.hits.total !== 0;
    }

    constructor(
        private store: Store,
        private corpus: CorpusApi,
        private dashboardApi: DashboardApi,
        private modalService: BsModalService,
        private ngModalService: NgbModal,
        private http: HttpClient,
        private dashboardService: DashboardService,
        private corpusService: CorpusService,
        private el:ElementRef,
        protected manageDashboardService : ManageDashboardService,
        private permissionsService : NgxPermissionsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        this.config = {
            style: {
                maxHeight: 'calc(100vh - 350px)',
                borderTop: 'none',
            },
            autoScroll: true,
            externalSort: true,
        };
    }

    corpusState$: Observable<GetCorpus>;

    get verbatimFieldName() {
        const verbatimFields: Array<Fieldname> = this.corpusService.currentCorpus.getValue().corp_verbatim_fields;
        const verbatimDbName : string = this.dashboardService.currentFilters.getValue().verbatim_dbname;
        const field = verbatimFields.find((f) => f.DBName === verbatimDbName);
        return (field) ? field.displayName : '';
    }

    ngOnInit(): void {
        this.corpusState$ = this.store.select(CorpusState);

        this.subs.sink = this.corpusState$.subscribe((corpus) => {
            if (corpus) {
                this.columnDate = {
                    displayName: corpus.corp_date_field.name,
                    DBName: corpus.corp_date_field.DBName,
                    type: SearchFieldType.DATE,
                    checked: true,
                };
            }
        });

        this.subs.sink = this.dashboardService.currentDashboard.subscribe(() => {
            this.initCorpusColumns();
            this.dashboardService.loadDashboardData();
        });

        this.subs.sink = this.dashboardService.dashboardData.subscribe((data) => {
            if (data != null) {
                this.pertIsDisabled = this.dashboardService.isPertinenceDisabled();
                this.initTables();
                this.updateColumnsSort();
                this.currentPage = this.dashboardService.currentFilters.getValue().page;
                this.onDataLoaded(data);
                // Si après avoir chargé les données, on voit que l'on avait sauvé une mise en favori, on l'effectue (suite à un saveAs sur un dashboard initial)
                if (this.manageDashboardService.verbatimFavoriteId) {
                    this.toogleSelectedRowFavorite(this.currentDash.dash_id, this.manageDashboardService.verbatimFavoriteId);
                    this.manageDashboardService.verbatimFavoriteId = '';
                }
            }
        });

        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe((params) => {
            const searchParams = this.dashboardService.currentFilters.getValue();
            if (searchParams !== null) {
                this.currentPage = searchParams.page;
                this.search = searchParams.freesearch || '';
                if (!params?.keepVerbatimPage) {
                    this.dashboardService.setPage(1);
                }
                this.removeDeprecatedColumns();
                this.dashboardService.loadDashboardData();
            }

            // on vérifie les colonnes de classe sélectionées correspondent toujours au verbatil sélectionné, sinon, on les supprime
        });
    }

    /**
     * Certaines colonnes du tableau des verbatim dépendent du verbatim sélectionné(les classes).
     * Si la colonne des verbatim change, alors il faut supprimer les colonnes de classes qui correspondent à l'ancienne valeur.
     */
    removeDeprecatedColumns() {
        const filters = this.dashboardService.currentFilters.getValue();
        const deprecatedClassColums = this.classColumns.filter((column) => column.type === SearchFieldType.CLASS && column.DBName !== filters.verbatim_dbname && column.checked);

        if (deprecatedClassColums.length > 0) {
            deprecatedClassColums.forEach((deprecatedColumn) => {
                deprecatedColumn.checked = false;
            });
            this.dashboardService.setSelectedColumns(_.cloneDeep(this.selectedColumns));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    classToString(value) {
        return value instanceof Array ? value.join('; ') : value;
    }

    // Quand on clique sur un mot du nuage de mots
    wordSelected(word: CloudData) {
        gtmClick({
            track_category: 'nuage de mots',
            track_name: 'capsule',
            track_cible: analytics.top_keywords_to_track_cible[word.subCategory],
        });
        // Find if the array contains an object by comparing the property value
        if (!this.keywordsearch.some((keyword) => `top_${keyword.inputName}` === word.subCategory && keyword.values[0] === word.key)) {
            this.dashboardService.setKeywordsSearch(this.keywordsearch.concat([
                {
                    inputName: word.subCategory.replace('top_', ''),
                    values: [word.key],
                    animate: true,
                },
            ]));
        }
    }

    initTables() {
        if (!this.currentPage) {
            this.currentPage = 1;
        }
        const dashboardWidth = this.el.nativeElement.querySelector('#exploreVerbatimTitle').clientWidth;
        const colWidth = (dashboardWidth * 2) / 3 - 80;
        const textWidth = colWidth < 700 ? colWidth : 700;

        this.columns = [{
            prop: this.dashboardService.currentFilters.getValue().verbatim_dbname,
            sort: false,
            titleNoTranslate: this.verbatimFieldName,
            wordWrap: true,
            style: {
                'min-width': `${textWidth}px`,
                'max-width': `${textWidth}px`,
                'max-height': 'calc(this.elementRef.nativeElement.querySelector(`#SpaceForWords`).clientHeight - 60px)',
                'word-wrap': 'break-word',
            },

        },
        ];

        if (this.sortFavoField?.checked) {
            this.columns.push({
                prop: 'sort_favo',
                icon: 'vcrm-star-favourite',
                sort: true,
                cellTemplate: this.favoriteRowTpl,
            });
        }

        if (this.sortAnnoField?.checked && this.permissionsService.getPermissions().corpusAdmin) {
            this.columns.push({
                prop: 'sort_anno',
                icon: 'vcrm-pencil',
                sort: true,
                cellTemplate: this.annotationsRowTpl,
            });
        }

        // On ajoute toutes les colonnes sélectionnées
        this.selectedColumns.forEach((column) => {
            if (column.type === SearchFieldType.CLASS) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const model = Object.values(this.store.snapshot().corpus.models).find((value) => value['mode_id'] === column.mode_id);
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const prefix = model['mode_prefix'];
                const prop = prefix && prefix !== '' ? `${prefix}.motifs_display` : 'motifs_display';
                if (model) {
                    this.columns.push({
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                        prop,
                        sort: false,
                        title: 'translations.analysisDashboard.verbatim.choiceColumns.classes',
                        wordWrap: true,
                        style: {
                            'max-width': '700px',
                        },
                        cellTemplate: this.classesTpl,
                    });
                }
            } else if (column.type !== SearchFieldType.SORT_ANNO && column.type !== SearchFieldType.SORT_FAVO) {
                this.columns.push({
                    prop: column.DBName,
                    sort: this.pertIsDisabled || !this.pertIsChecked,
                    titleNoTranslate: column.displayName,
                    wordWrap: true,
                    style: {
                        'max-width': '700px',
                    },
                });
            }
        });
    }

    get pertIsChecked() {
        return this.dashboardConfig.verbatim.pert_is_checked;
    }

    set pertIsChecked(value: boolean) {
        this.dashboardService.setPertinenceSort(value);
    }

    get keywordsearch() : Array<KeywordSearch> {
        return this.dashboardService.currentFilters.getValue().keywordsearch;
    }

    get isZoomIn() {
        return this.dashboardConfig.verbatim.extended_table;
    }

    set isZoomIn(value: boolean) {
        this.dashboardService.setVerbatimConfig(VerbatimConfigField.extended_table, value);
    }

    /**
   * Initialize columns list with the right format
   */
    initCorpusColumns(): void {
    // Reset corpusColumn (when dash change for example)
        this.corpusColumns = [];
        const searchField = this.getSearchField();
        this.corpusColumns.push(this.columnDate);

        // On ajoute les autres colonnes
        this.store.snapshot().corpus.corp_fields.forEach((f) => this.corpusColumns.push({ displayName: f.displayName, DBName: f.DBName, type: SearchFieldType.COLUMN }));
        this.store.snapshot().corpus.corp_fields_not_in_file.forEach((f) => this.corpusColumns.push({ displayName: f.displayName, DBName: f.DBName, type: SearchFieldType.TREATMENT }));
        Object.keys(this.store.snapshot().corpus.models).forEach((key) => {
            const currentModel = this.store.snapshot().corpus.models[key];
            this.corpusColumns.push({
                displayName: currentModel.model, DBName: currentModel.verbatim_dbname, type: SearchFieldType.CLASS, mode_id: currentModel.mode_id,
            });
        });

        // On update la config et on met à jour les columns
        const config = this.dashboardService.currentConfig.getValue();
        if (config !== null) {
            this.dashboardConfig = config;

            if (config.verbatim.selected_columns) {
                config.verbatim.selected_columns.forEach((column) => {
                    const corpusColumn = this.corpusColumns.find((c) => c.DBName === column.DBName);
                    if (corpusColumn && corpusColumn !== null) {
                        corpusColumn.checked = true;
                    }
                });
            }
            this.initTables();
        }

        // ajout de colonnes "fictives" pour le tri par annotations et verbatims favoris

        this.corpusColumns.push({
            displayName: '',
            DBName: 'sort_favo',
            type: SearchFieldType.SORT_FAVO,
            mode_id: undefined,
            checked: Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'sort_favo') ? this.dashboardConfig.sort_favo : true,

        });

        this.corpusColumns.push({
            displayName: '',
            DBName: 'sort_anno',
            type: SearchFieldType.SORT_ANNO,
            mode_id: undefined,
            checked: Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'sort_anno') ? this.dashboardConfig.sort_anno : true,
        });

        const searchColumn = this.corpusColumns.find((column) => column.DBName === searchField?.DBName) || this.columnDate;
        searchColumn.sort = searchField?.sort || SearchSortOrder.DESC;
        this.saveSortedColumn = { ...searchColumn };
    }

    /**
   * Fired when modalSearchColumn is update
   */
    onChangeSearchColumn(): void {
        this.isAccordionOpen = [true, true, true];
    }

    /**
   * Fired when user click on accordion item to open or close it
   */
    onToggleAccordion(target, index): void {
        if (target.parentElement.hasAttribute('aria-expanded')) {
            this.isAccordionOpen[index] = target.parentElement.getAttribute('aria-expanded') === 'true';
        }
    }

    getSearchField() {
        return this.dashboardService.currentFilters.value.search_fields.find((f) => f.sort);
    }

    /**
   * Event fired when user click on "Valid" button on the choice column modal
   */
    updateVisibleColumns(): void {
        const searchField = this.getSearchField();
        const sortColumn = this.selectedColumns.find((f) => f.sort);
        // on revient à la première page si on change de colonne de tri ou d'ordre de tri
        if (searchField?.DBName !== sortColumn?.DBName || searchField?.sort !== sortColumn?.sort) {
            this.dashboardService.setPage(1);
        }
        // On applique la colonne pour la nouvelle recherche
        this.dashboardService.setSelectedColumns(_.cloneDeep(this.selectedColumns));

        this.removeDeprecatedColumns();

        this.dashboardService.loadDashboardData();
    }

    get currentPropSort() {
        return this.selectedColumns.find((column) => column.sort !== undefined)?.DBName || this.columnDate.DBName;
    }

    get currentSortMode() {
        return this.selectedColumns.find((column) => column.sort !== undefined)?.sort || 'desc';
    }

    /**
   * Event fired when user click on "Cancel" button on the choice column modal
   */
    closeVisibleColumns(): void {
        this.modalSearchColumns = '';
        // On désactive toutes les colonnes sélectionnées
        this.selectedColumns.forEach((col) => {
            col.checked = false;
        });
        // On sélectionne uniquement celle de la requete en cours

        this.dashboardService.currentFilters.value.search_fields.forEach((s) => {
            const dbColumn = this.corpusColumns.find((c) => c.DBName === s.DBName);
            if (dbColumn) {
                dbColumn.checked = true;
            }
        });

        this.sortAnnoField.checked = Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'sort_anno') ? this.dashboardConfig.sort_anno : true;
        this.sortFavoField.checked = Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'sort_favo') ? this.dashboardConfig.sort_favo : true;

        this.isAccordionOpen = [true, true, true, true, true];
    }

    get sortAnnoField() {
        return this.corpusColumns.find((column) => column.DBName === 'sort_anno');
    }

    get sortFavoField() {
        return this.corpusColumns.find((column) => column.DBName === 'sort_favo');
    }

    onDataLoaded(currentCorpusData) {
        this.currentCorpusData = currentCorpusData;

        const verbatims = [];
        this.total = currentCorpusData.hits.total || 0;
        this.pageCount = currentCorpusData.hits.total_pages || 0;

        if (Object.prototype.hasOwnProperty.call(currentCorpusData, 'hits')
            && Object.prototype.hasOwnProperty.call(currentCorpusData.hits, 'total_pages')) {
            this.pageCount = currentCorpusData.hits.total_pages;
        }

        this.config.style.maxHeight = `calc(100vh - ${(this.pageCount > 1) ? '340' : '285'}px)`;

        let stringsToHighligth: string[] = (this.search.match(/"[^"]+"|[\S^"]+/gi) || []).map((value) => value.replace(/"/g, ''));

        stringsToHighligth = stringsToHighligth.filter((value) => (value.trim().startsWith('@') || value.trim().startsWith('#')));

        this.keywordsearch.forEach((keyword) => {
            stringsToHighligth.push(keyword.values[0]);
        });

        const regex: RegExp = stringsToHighligth.length === 0 ? null : new RegExp(`(${stringsToHighligth.join('|')})`, 'gi');

        if (Object.prototype.hasOwnProperty.call(currentCorpusData.hits, 'hits')) {
            currentCorpusData.hits.hits.forEach((verbatim) => {
                const verbatimLine = verbatim._source;
                verbatimLine._id = verbatim._id;
                verbatimLine.is_favorite = verbatim.is_favorite;
                if (verbatim._annotations_origine) {
                    verbatimLine._annotations_origine = verbatim._annotations_origine;
                }
                this.store.snapshot().corpus.corp_verbatim_fields.forEach((verbatimField) => {
                    const key = verbatimField.DBName;
                    if (verbatimLine[key]) {
                        if (verbatim.highlight && verbatim.highlight[key]) {
                            const [firstHighlightVerbatim] = verbatim.highlight[key];
                            verbatimLine[key] = firstHighlightVerbatim;
                        }

                        if (regex !== null) {
                            verbatimLine[key] = verbatimLine[key].replace(regex, '<span class="highlight">$1</span>');
                        }
                    }
                });

                verbatims.push(verbatimLine);
            });
        }
        this.verbatims = [...verbatims];
    }

    /**
   * Fired when user clic on switch pertinence button
   */
    onChangePertinence() {
        gtmClick({
            track_category: 'exploitation des verbatim',
            track_name: 'tri par pertinence',
            track_cible: this.pertIsChecked ? 'valider' : 'annuler',
        });
        this.updateColumnsSort();
        this.updateVisibleColumns();
    }

    updateColumnsSort() {
        if (this.pertIsChecked) {
            this.selectedColumns.forEach((c) => {
                if (c.type !== SearchFieldType.SORT_ANNO && c.type !== SearchFieldType.SORT_FAVO) {
                    delete c.sort;
                }
            });
        } else {
            // Si on désactive le tri par partinence, on remet le tri précedemment enregistré
            if (this.saveSortedColumn && this.selectedColumns.length > 0) {
                const findLastSortedColumn = this.selectedColumns.find((c) => c.DBName === this.saveSortedColumn.DBName);
                // Si on la colonne précédemment triée est toujours sélectionnée, on remet le tri dessus
                if (findLastSortedColumn) {
                    findLastSortedColumn.sort = this.saveSortedColumn.sort;
                }
            }
            // On recharge les colonnes du tableau pour activer le tri
            this.initTables();
        }
        this.initTables();
    }

    /**
   * Event fired when user click on a header column to sort it
   */
    onSortCol(column): void {
    // On parcourt les colonnes sélectionnées pour supprimer le tri et le mettre uniquement sur la colonne cliquée (on sauvegarde au passage la colonne triée)
        this.corpusColumns.forEach((f) => {
            if (f.DBName === column.prop) {
                f.sort = column.order ? SearchSortOrder.ASC : SearchSortOrder.DESC;
                this.saveSortedColumn = { ...f };
            } else {
                delete f.sort;
            }
        });
        this.updateVisibleColumns();
    }

    // eslint-disable-next-line class-methods-use-this
    onlyPageHasChange(changes: SimpleChanges) {
        let hasPropNotCurrentPage = true;
        // si on ne détecte qu'un changement de page
        Object.keys(changes).forEach((propName) => {
            if (propName !== 'currentPage') {
                hasPropNotCurrentPage = false;
            }
        });
        return hasPropNotCurrentPage;
    }

    public changePage(pageNumber: number) {
        this.dashboardService.setPage(pageNumber);
        this.dashboardService.loadDashboardData();
    }

    openModal(template: TemplateRef<any>, selectedColumn = false) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });
        if (selectedColumn) {
            this.saveLastSeletedColumn = [...this.selectedColumns];
        }
    }

    get currentDash() {
        return this.dashboardService.currentDashboard.getValue();
    }

    downloadFile() {
        const downloadReq = this.initDownloadData();

        if (this.selectedType === ExportType.EXCEL) {
            downloadReq.format = ExportType.EXCEL;
        }

        this.http.post(`${this.corpus.rootUrl}/v1/dashboard/${this.currentDash.dash_id}/data/download`, downloadReq, { responseType: 'blob', observe: 'response' }).subscribe((resp) => {
            const filename = resp.headers.get('content-disposition').split('=')[1];
            download(window.URL.createObjectURL(resp.body), filename);
            this.selectedType = null;
        });
    }

    downloadAllFile() {
        const downloadReq = this.initDownloadData();

        if (this.selectedType === ExportType.EXCEL) {
            downloadReq.format = ExportType.EXCEL;
        }

        this.http.post(`${this.corpus.rootUrl}/v1/dashboard/${this.currentDash.dash_id}/data/download/all`, downloadReq, { responseType: 'blob', observe: 'response' })
            .subscribe({
                next: () => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.verbatim.exportallSuccess';
                    modal.componentInstance.alertTypeVariant = 'info';
                    this.selectedType = null;
                },
                error: () => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                    modal.componentInstance.alertTypeVariant = 'danger';
                },

            });
    }

    /**
   * Initialise les paramètres à envoyer pour les requêtes de download
   */
    initDownloadData():CorpusDataDownload {
        const downloadReq: CorpusDataDownload = {};
        downloadReq.searchparams = JSON.parse(JSON.stringify(this.dashboardService.lastAppliedFilters.value));
        // Si le bouton "tri par pertinence" est coché, on désactive tous les tris de la recherche
        if (this.pertIsChecked) {
            downloadReq.searchparams.search_fields.forEach((c) => {
                if (c.type !== SearchFieldType.SORT_ANNO && c.type !== SearchFieldType.SORT_FAVO) {
                    delete c.sort;
                }
            });
        } else if (this.selectedColumns.length === 0) {
            // Sinon si on a aucune colonne de sélectionner
            // Alors on envoi le tri par date (qui est le tri par defaut)
            downloadReq.searchparams.search_fields.push({ ...this.columnDate, sort: SearchSortOrder.DESC });
        }
        downloadReq.columns = [];

        const columns = this.store.snapshot().corpus.corp_fields;
        columns.forEach((col) => {
            downloadReq.columns.push(col.inputName);
        });
        return downloadReq;
    }

    canBeAnnotated(verbatim) {
        const currentModel = this.dashboardService.getCurrentModel();
        const hasAnnotableModel = this.store.snapshot().settings.vcrm['model-types-for-annotations'].some((model) => Object.prototype.hasOwnProperty.call(model, currentModel?.mode_type.toLowerCase()));
        const hasGlobalTone = verbatim.sentiment;

        return hasAnnotableModel || hasGlobalTone;
    }

    openAnnotationModal(row) {
        const currentIndex = this.verbatims.findIndex((verbatim) => verbatim._id === row._id);

        const modal = this.ngModalService.open(AnnotationsModalComponent, { size: 'full-width', backdrop: 'static', keyboard: false });
        modal.componentInstance.verbatims = this.verbatims;
        modal.componentInstance.currentIndex = currentIndex;
        modal.componentInstance.verbatimDbName = this.dashboardService.lastAppliedFilters.getValue().verbatim_dbname;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.manageDashboardService = this.manageDashboardService;
    }

    async toggleFavorite(row) {
        this.selectedFavId = row._id;
        // si on est sur un dashboard initial, on force d'abord la sauvegarde en dashboard personnel
        if (this.currentDash.dash_initial) {
            this.saveName = this.manageDashboardService.computeDashboardName();

            this.openModal(this.saveAsBeforeFavorite);
        } else {
            this.toogleSelectedRowFavorite(this.currentDash.dash_id, this.selectedFavId);
        }
    }

    async toogleSelectedRowFavorite(dashId, rowId) {
        const selectedRow = this.verbatims.find((verbatim) => verbatim._id === rowId);

        gtmClick({
            track_category: 'exploitation des verbatim',
            track_name: 'mise en favori du verbatim',
            track_cible: selectedRow?.is_favorite ? 'enlever le favori' : 'mettre en favori',
        });

        if (selectedRow?.is_favorite) {
            await firstValueFrom(this.dashboardApi.removeFavoriteVerbatim(dashId, rowId));
        }
        await firstValueFrom(this.dashboardApi.addFavoriteVerbatim(dashId, rowId));

        if (selectedRow) {
            selectedRow.is_favorite = !selectedRow.is_favorite;
        }
    }

    saveAsDbAndFavorite(name) {
        this.modalRef.hide();
        // On enregistre l'élément mis en favori par l'utilisateur pour l'appliquer une fois le nouveau dashboard complètement chargé
        this.manageDashboardService.verbatimFavoriteId = this.selectedFavId;
        this.manageDashboardService.saveAs(name);
    }
}
