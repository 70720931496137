import { Directive, Input, HostListener } from '@angular/core';
import { environment } from 'environments/environment';
import { GtmEvent } from './gtm.model';

export function gtmClick(track?: GtmEvent) {
    dataLayer.push({
        event: 'clic',
        site_name: 'visualcrm-ow',
        phase: environment.phase,
        track_category: '',
        track_name: '',
        track_cible: '',
        ...track,
    });
}

@Directive({
    selector: '[appGtm]',
})
export default class GtmDirective {
    @Input()
        appGtm: GtmEvent = { track_category: '', track_cible: '', track_name: '' };

    @HostListener('click')
    onClick() {
        gtmClick({ ...this.appGtm });
    }
}
