import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import { ExplorationLLM, LLMStatus } from 'app/api/models/llm';
import { DashboardService as DashboardApi } from 'app/api/services';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { ChatMode } from 'app/api/models/conversation';

@Component({
    selector: 'app-lab-llm',
    templateUrl: './lab-llm.component.html',
    styleUrls: ['./lab-llm.component.scss'],
})
export default class LabllmComponent extends BaseComponent implements OnInit {
    modalRef: NgbModalRef;

    explorationsLLM: ExplorationLLM[] = [];

    // Détermine si les filtres sont en cours de mise à jour
    isFiltersUpdated = false;

    @ViewChild('dashNotSaveModal')
    private dashNotSaveModal:TemplateRef<any>;

    // Détermine si le chat est ouvert ou non (désactive le bouton pour lancer une découverte si le chat est ouvert)
    get isDinootooChatOpen() {
        return this.chatService.isChatDisplay;
    }

    constructor(
        private store: Store,
        private chatService: ChatService,
        private dashboardApi: DashboardApi,
        private modalService: NgbModal,
        protected manageDashboardService: ManageDashboardService,
        private dashboardService: DashboardService,
    ) {
        super();
    }

    async ngOnInit() {
        // Ecoute la demande de nouvelle découverte (venant du chat dinootoo)
        this.subs.sink = this.chatService.launchDiscovery.subscribe((newDiscovery) => {
            if (newDiscovery) {
                this.addNewDiscovery(newDiscovery);
            }
        });

        // Détermine si il y a eu des modifications de filtres
        this.subs.sink = this.manageDashboardService.firstDashboardService.hasFilterChangesNotApplied.subscribe((value) => {
            this.isFiltersUpdated = value;
        });

        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            // Récupère toutes les découvertes pour ce dashboard
            const allDiscoveries = await firstValueFrom(this.dashboardApi.getDiscovery(this.manageDashboardService.currentDashboard.getValue().dash_id));
            if (allDiscoveries.discoveries.length) {
                let explorationLLMDetail = [];
                try {
                    // Récupère le détail de chaque découverte qui est en status DONE
                    explorationLLMDetail = await Promise.all(
                        allDiscoveries.discoveries
                            .filter((explo) => explo.llm_status === LLMStatus.DONE)
                            .map((explo) => firstValueFrom(
                                this.dashboardApi.getDiscoveryId(this.manageDashboardService.currentDashboard.getValue().dash_id, explo.llm_id, this.getDiscoveryIdParam(explo.llm_id)),
                            )),
                    );
                } catch (e) {
                    // Nothing
                }
                // Met à jour le tableau des découvertes avec les données en détail pour les découvertes terminées et les données normales pour les autres
                this.explorationsLLM = allDiscoveries.discoveries.map((explo) => ((explo.llm_status === LLMStatus.DONE) ? explorationLLMDetail.find((exp) => exp.llm_id === explo.llm_id) : explo));
            }
        });
    }

    /**
     * Ajoute une nouvelle découverte dans le tableau
     */
    addNewDiscovery(newDiscovery: ExplorationLLM) {
        this.explorationsLLM.push(newDiscovery);
        setTimeout(() => {
            document.getElementById(`llmSection-${newDiscovery.llm_id}`).scrollIntoView();
        }, 250);
    }

    /**
     * Evènement sur le clic du bouton "Commencer la découverte"
     * Affiche une modale pour sauvegarder si le dash a été modifié
     * Sinon si ok, ouvre le chat avec l'onglet Découverte IA de sélectionner
     */
    onStartDiscovery() {
        if (this.dashboardService.dashboardIsModified || this.dashboardService.currentDashboard.getValue().dash_initial) {
            this.modalRef = this.modalService.open(this.dashNotSaveModal);
        } else {
            this.chatService.isChatDisplay = true;
            this.chatService.chatMode.next(ChatMode.DISCOVERY);
        }
    }

    // Méthode appelée lors de la mise à jour du nom de la découverte
    async onUpdateDiscovery(disco: ExplorationLLM, newName: string) {
        await firstValueFrom(this.dashboardApi.patchDiscoveryId(this.manageDashboardService.currentDashboard.getValue().dash_id, disco.llm_id, newName));
        const index = this.explorationsLLM.findIndex((exp) => exp.llm_id === disco.llm_id);
        if (index !== -1) {
            this.explorationsLLM[index].llm_name = newName;
        }
    }

    // Méthode appelée lorsque l'on relance une découverte (qui avait échouée)
    async onReloadDiscovery(disco: ExplorationLLM) {
        const updatedDiscovery = await firstValueFrom(this.dashboardApi.putDiscoveryId(this.manageDashboardService.currentDashboard.getValue().dash_id, disco.llm_id));

        const index = this.explorationsLLM.findIndex((exp) => exp.llm_id === disco.llm_id);
        if (index !== -1) {
            this.explorationsLLM[index] = updatedDiscovery;
        }
    }

    get currentConfig() {
        return this.dashboardService
            ? this.dashboardService.currentConfig
            : this.manageDashboardService.currentDashboardComparisonConfig;
    }

    getDiscoveryIdParam(id: number) {
        return this.currentConfig.getValue().llmProfileConfig.find((c) => c.id === id);
    }

    // Méthode appelée lorsque l'on souhaite rafraichir le status d'une découverte (lorsqu'elle est en cours)
    async onRefreshDiscovery(disco: ExplorationLLM) {
        const newExplorationLlm = await firstValueFrom(
            this.dashboardApi.getDiscoveryId(this.manageDashboardService.currentDashboard.getValue().dash_id, disco.llm_id, this.getDiscoveryIdParam(disco.llm_id)),
        );
        const index = this.explorationsLLM.findIndex((exp) => exp.llm_id === disco.llm_id);
        if (index !== -1) {
            this.explorationsLLM[index] = newExplorationLlm;
        }
    }

    // Méthode appelée pour supprimer une découverte
    onDeleteDiscovery(disco: ExplorationLLM) {
        this.dashboardApi.deleteDiscoveryId(this.manageDashboardService.currentDashboard.getValue().dash_id, disco.llm_id);
        const index = this.explorationsLLM.findIndex((exp) => exp.llm_id === disco.llm_id);
        if (index !== -1) {
            this.explorationsLLM.splice(index, 1);
        }
    }
}
