import { HttpClient } from '@angular/common/http';
import {
    Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import byteSize from 'byte-size';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseComponent } from 'app/base.component';
import { environment } from '../../../../../environments/environment';
import { AddFile } from '../../../../stores/actions/new-corpus/new-corpus.actions';
import * as Resumable from '../../../../../libs/resumable';

/**
 * Select file for corpus
 *
 * @export
 * @class Etape2uploadComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape2upload-csvxls',
    templateUrl: './etape2upload-csvxls.component.html',
    styleUrls: ['./etape2upload-csvxls.component.scss'],
})
export default class Etape2uploadCsvxlsComponent extends BaseComponent implements OnInit {
    filename: string;

    openParams: boolean;

    encoding: string = 'utf8';

    delimiter: string;

    filesize: number;

    filenameExt: string;

    filenameExtTest: string;

    r: any;

    fileUploadPercent: number = 0;

    // next: boolean = false;
    storeDatas: any;

    @ViewChild('verbatimFile') uploadFile: ElementRef;

    fileUploadError = false;

    public error = null;

    settings$: Observable<SettingsModel>;

    gdrpText = '';

    // IB
    fileExtension: any;

    fileExtensionError: boolean = false;

    // allowedExtensions = ['csv', 'xls', 'xlsx'];
    allowedExtensions;

    @Input() nextButtonObservable: Observable<void>;

    @Output() nextButtonActivation = new EventEmitter<boolean>();

    get helpPage() {
        return `/help${this.translate.currentLang === 'en' ? '-en' : ''}/books/creation-dun-projet/page/les-pre-requis-a-la-creation-dun-projet`;
    }

    constructor(
        private store: Store,
        private router: Router,
        private http: HttpClient,
        private translate: TranslateService,
    ) {
        super();
        this.allowedExtensions = this.store.snapshot().settings.vcrm.allowed_extensions;
        this.openParams = false;

        const state = this.store.snapshot().newcorpus;
        if (!state.datas || !state.datas.name) {
            this.router.navigate(['new-project']);
        } else {
            this.storeDatas = state.datas;

            this.gdrpText = this.store.snapshot().settings.vcrm[
                `gdpr_maintext_${this.translate.currentLang}`
            ];

            this.store.dispatch(
                new AddFile({
                    name: '',
                    delimiter: '',
                    encoding: '',
                    corpusType: 1,
                }),
            );
        }
    }

    ngOnInit() {
        this.subs.sink = this.nextButtonObservable.subscribe(() => {
            this.selectFile();
        });

        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.gdrpText = settings.vcrm[
                `gdpr_maintext_${this.translate.currentLang.toLowerCase()}`
            ];
        });

        this.translate
            .get('translations.newcorpus.stage2.noFile')
            .subscribe((nofile) => {
                this.filenameExt = nofile;
            });

        this.checkFileSelected();

        this.r = new Resumable({
            target: `${environment.api}/api/v1/file/upload`,
            query: {
                delimiter: this.delimiter,
            },
            withCredentials: true,
            method: 'multipart',
            testChunks: true,
            simultaneousUploads: 1,
            maxFiles: 1,
        });
        this.r.assignBrowse(document.getElementById('fileImport'), false);
        this.r.on('progress', () => {
            const percent = Math.floor(this.r.progress() * 100);
            this.fileUploadPercent = percent;
            FavIconX.config({
                shape: 'doughnut',
                borderWidth: 0,
                fillColor: '#f16e00',
            }).setValue(percent);
        });

        this.r.on('fileError', (e, message) => {
            if (typeof message === 'string') {
                this.error = JSON.parse(message);
            } else {
                this.error = message;
            }

            this.error.msgToTranslate = `translations.httpErrors.${this.error?.error || 'title'}`;
            FavIconX.fail();
            this.fileUploadPercent = 0;
            this.fileUploadError = true;

            // US_445051: in case of error, we also reset the input field to force the user upload a new file
            this.resetInputField();
        });

        this.r.on('fileSuccess', () => {
            FavIconX.reset();
            this.error = null;
            this.router.navigate(['new-project', 'date']);
        });
    }

    resetInputField() {
        this.openParams = false;
        this.uploadFile.nativeElement.value = '';
        this.filename = '';
        this.filenameExt = '';
        this.fileExtension = '';
    }

    checkFileSelected() {
        if (
            _.isEmpty(this.encoding)
        || _.isEmpty(this.delimiter)
        || _.isEmpty(this.filename)
        ) {
            this.nextButtonActivation.emit(false);
        } else {
            this.nextButtonActivation.emit(true);
        }
    }

    /* - checks if word exists in array - IB */
    // eslint-disable-next-line class-methods-use-this
    isInArray(array, word) {
        return array.indexOf(word.toLowerCase()) > -1;
    }

    onFileChange(evt) {
        this.openParams = false;
        this.delimiter = '';
        this.filename = '';
        this.nextButtonActivation.emit(false);
        this.r.cancel();
        this.error = null;
        const target: DataTransfer = <DataTransfer>evt.target;
        if (target.files && target.files.length === 1) {
            this.filename = evt.target.files[0].name;
            this.fileExtension = this.filename.split('.').pop(); // IB
            this.filesize = evt.target.files[0].size;
            this.filenameExt = `${this.filename} (${this.sizeF(this.filesize)})`;
            if (this.isInArray(this.allowedExtensions, this.fileExtension)) {
                this.fileExtensionError = false;
                if (this.fileExtension === 'csv') {
                    this.openParams = true;
                } else { // xls ou xlsx
                    this.nextButtonActivation.emit(true);
                    this.delimiter = ',';
                }
                this.store.dispatch(
                    new AddFile({
                        name: this.filename,
                        delimiter: this.delimiter,
                        encoding: this.encoding,
                        corpusType: 1,
                    }),
                );
            } else {
                this.fileExtensionError = true;
            }
        }
    }

    onChange() {
        this.store.dispatch(
            new AddFile({
                name: this.filename,
                delimiter: this.delimiter,
                encoding: this.encoding,
                corpusType: 1,
            }),
        );
        this.checkFileSelected();
    }

    selectFile() {
        this.http
            .delete('/api/v1/file', {
                withCredentials: true,
            })
            .subscribe({
                next: () => {
                    this.nextButtonActivation.emit(false);
                    this.r.opts.query.delimiter = this.delimiter;
                    this.r.upload();
                },
                error: () => {
                    this.nextButtonActivation.emit(false);
                    this.r.opts.query.delimiter = this.delimiter;
                    this.r.upload();
                },
            });
    }

    // eslint-disable-next-line class-methods-use-this
    sizeF(size) {
        return byteSize(size, { units: 'metric_octet' });
    }
}
