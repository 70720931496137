import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import CorpusService from 'app//utils/services/corpus.service';
import { BaseComponent } from 'app/base.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import sortArrayByProp from 'app/utils/functions/sortArrayByProp';

@Component({
    selector: 'app-dashboard-project',
    templateUrl: './dashboard-project.component.html',
    styleUrls: ['./dashboard-project.component.scss'],
})
export default class DashboardProjectComponent extends BaseComponent implements OnInit {
    public titlePage: string;

    public currentCorpus;

    public isFavorite: boolean = false;

    public corpusPrivate = [];

    public corpusPublic = [];

    constructor(
        private router: Router,
        private corpusService: CorpusService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getTitleFromUrl(this.router.url);

        this.subs.sink = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.getTitleFromUrl(event.url);
            }
        });

        this.subs.sink = this.corpusService.currentCorpus.subscribe((c) => {
            this.currentCorpus = c;
        });

        this.subs.sink = this.corpusService.corpusList.subscribe((data) => {
            if (data !== null) {
                this.corpusPrivate = sortArrayByProp(data.corpusPrivate, 'dateLastUpload');
                this.corpusPublic = sortArrayByProp(data.corpusPublic, 'dateLastUpload');

                const allCorpus = [...data.corpusPrivate, ...data.corpusPublic];
                this.isFavorite = allCorpus.find((corp) => corp.corp_id === this.currentCorpus.corp_id)?.is_favori;
            }
        });
    }

    /**
     * Détermine le nom du titre à afficher en fonction de l'url
     */
    getTitleFromUrl(url: string): void {
        if (url.endsWith('analyses')) {
            this.titlePage = 'analysis';
        } else if (url.endsWith('tones')) {
            this.titlePage = 'tones';
        } else if (url.includes('settings')) {
            this.titlePage = 'settings';
        } else if (url.includes('thematics')) {
            this.titlePage = 'thematics';
        }
    }

    /**
     * Evènement lors du clique sur le bouton favori.
     * Met ou retire des favoris le projet en cours.
     */
    onToggleFav() {
        const titleTones = (this.titlePage === 'tones' ? 'dashboard tonalité' : 'gestion');
        gtmClick({
            track_category: (this.titlePage === 'analysis' ? 'analyse' : titleTones),
            track_name: 'ajout favori projet',
            track_cible: this.isFavorite ? 'enlever le favori' : 'mettre en favori',
        });
        if (this.isFavorite) {
            this.corpusService.unFav(this.currentCorpus.corp_id);
        } else {
            this.corpusService.fav(this.currentCorpus.corp_id);
        }
        this.isFavorite = !this.isFavorite;
    }

    /**
     * Evènement lors de la sélection d'un nouveau projet
     * @param corpusId
     */
    onSelectProject(corpusId) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate([`/project/${corpusId}`]);
    }
}
