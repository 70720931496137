import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import SettingsState from 'app/stores/state/settings/settings.state';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import { CorpusType } from 'app/api/models/corpustype';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SetEtapes } from 'app/stores/actions/new-corpus/new-corpus.actions';
import { BaseComponent } from 'app/base.component';

/**
 * Select file for corpus
 *
 * @export
 * @class Etape2uploadComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape2upload',
    templateUrl: './etape2upload.component.html',
    styleUrls: ['./etape2upload.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape2uploadComponent extends BaseComponent implements OnInit {
    nextButtonSubject: Subject<void> = new Subject<void>();

    nextButtonActivated:boolean = false;

    corpusTypes : Array<CorpusType>;

    currentLangage: string;

    selectedCorpusType: number = -1;

    modalRef: NgbModalRef;

    settings$: Observable<SettingsModel>;

    @ViewChild('gdprInfoModal')
    private gdprModal: TemplateRef<any>;

    constructor(
        private translate: TranslateService,
        private modalService: NgbModal,
        private store: Store,
    ) {
        super();
    }

    ngOnInit() {
        this.currentLangage = this.translate.currentLang;

        setTimeout(() => {
            this.store.dispatch(new SetEtapes(1));
        }, 500);

        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.corpusTypes = settings.vcrm.corpus_type;
        });
    }

    next() {
        this.nextButtonSubject.next();
    }

    /**
   * Le texte est codé en dur comme spécifié
   */
    getGoogleAnalyticsCible(corpusType) {
        switch (corpusType.id) {
            case 1: {
                return 'csv ou excel';
            }
            case 2: {
                return 'MSurveys avec compte';
            }
            case 3: {
                return 'MSurveys avec URL';
            }
            default: {
                // si c'est un type non géré par le frontend, on renvoit la description du backend
                return corpusType[this.currentLangage];
            }
        }
    }

    toggleNextButton(value:boolean) {
        this.nextButtonActivated = value;
    }

    openGdprModal(e) {
        e.preventDefault();
        this.modalRef = this.modalService.open(this.gdprModal, {
            backdrop: 'static',
        });
    }

    closeModal() {
        this.modalRef.close();
    }
}
