import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Inputnamevalues, DashboardType } from 'app/api/models';
import { CorpusService as CorpusApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';
import FiltersAssociatedDataPopupComponent from './components/filters-associated-data-popup/filters-associated-data-popup.component';

@Component({
    selector: 'app-filters-associated-data',
    templateUrl: './filters-associated-data.component.html',
    styleUrls: ['./filters-associated-data.component.scss'],
})
export default class FiltersAssociatedDataComponent extends BaseVerticalFilterComponent implements OnInit {
    constructor(
        private dashboardService: DashboardService,
        private corpusApi: CorpusApi,
        private modalService: NgbModal,
        private corpusService: CorpusService,
        private router: Router,
        private activatedRoute:ActivatedRoute,
    ) {
        super(dashboardService);
    }

    columnNumber: number = -1;

    fields = [];

    ngOnInit() {
        super.ngOnInit();

        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.columnNumber = corpus.corp_fields.filter((field) => (this.dashboardService.currentDashboardType === DashboardType.ANALYSE ? field.analyseFilter : field.toneFilter)).length;
            }
        });

        this.subs.sink = this.dashboardService.currentFilters.subscribe(
            (filters) => {
                if (filters !== null) {
                    this.getAsociatedDataFields(filters);
                }
            },
        );
    }

    get treatmentColumns() : string[] {
        const result : string[] = [];
        this.corpusService.currentCorpus.getValue().corp_fields_not_in_file.forEach((field) => {
            if (field.isTreatment) {
                result.push(field.inputName);
            }
        });
        return result;
    }

    getAsociatedDataFields(filters) {
        const { treatmentColumns } = this;
        this.fields = [];

        filters.filters.filter((filter) => !treatmentColumns.includes(filter.inputName)).forEach(
            (filter) => {
                this.fields.push(filter);
            },
        );
    }

    deleteOneValue(name: string, value: string) {
        const matchingName: Array<Inputnamevalues> = this.filters.filter(
            (filter) => filter.inputName === name,
        );
        matchingName.forEach((filter) => {
            filter.values = filter.values.filter(
                (filterValue) => filterValue !== value,
            );
        });
        this.filters = this.filters.filter(
            (filter) => filter.values.length > 0,
        );
        this.dashboardService.updateFilterList(this.filters);
    }

    deleteAllByName(name: string) {
        this.filters = this.filters.filter(
            (filter) => filter.inputName !== name,
        );
        this.dashboardService.updateFilterList(this.filters);
    }

    openFiltersModal() {
        const modal = this.modalService.open(
            FiltersAssociatedDataPopupComponent,
        );
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.corpusApi = this.corpusApi;
        modal.componentInstance.onSave.subscribe(() => {
            this.collapsed = false;
        });
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'gestion filtre',
        });
    }

    get count() {
        return this.filters
            ? this.filters
                .filter((filter) => !this.treatmentColumns.includes(filter.inputName))
                .reduce((sum, filter) => sum + filter.values.length, 0)
            : 0;
    }

    addColumns() {
        this.router.navigate(['../settings/columns'], { relativeTo: this.activatedRoute });
    }
}
