import {
    Component, OnInit, Input, Output, EventEmitter, OnDestroy,
} from '@angular/core';
import {
    trigger, state, style, transition, animate,
} from '@angular/animations';
import FooterService from 'app/shared/components/footer/footer.service';
import FixedValidationBarService from './fixed-validation-bar.service';

@Component({
    selector: 'app-fixed-validation-bar',
    templateUrl: './fixed-validation-bar.component.html',
    styleUrls: ['./fixed-validation-bar.component.scss'],
    animations: [
        trigger('openClose', [
            state('ask', style({
                bottom: '0px',
            })),
            state('confirm', style({
                bottom: '0px',
            })),
            state('closed', style({
                bottom: '-72px',
            })),
            transition('closed => ask', [
                animate('0.1s'),
            ]),
            transition('closed => confirm', [
                animate('0.1s'),
            ]),
            transition('confirm => closed', [
                animate('0.1s'),
            ]),
            transition('ask => closed', [
                animate('0s'),
            ]),
        ]),
    ],
})
export default class FixedValidationBarComponent implements OnInit, OnDestroy {
    @Output()
        validate = new EventEmitter<void>();

    @Output()
        cancel = new EventEmitter<void>();

    @Input()
        validateText: string;

    @Input()
        alignLeft : boolean = true;

    @Input()
        confirmDisabled: boolean = false;

    ask: boolean = true;

    confirm: boolean = false;

    constructor(
        private footerService: FooterService,
        private service: FixedValidationBarService,
    ) { }

    ngOnDestroy(): void {
        this.footerService.overlay(false);
    }

    @Input()
    set display(value: boolean) {
        if (value) {
            this.confirm = false;
        }
        this.ask = value;
        this.footerService.overlay(value);
    }

    showConfirm() {
        this.ask = false;
        this.confirm = true;
        this.footerService.overlay(false);
        setTimeout(() => {
            this.confirm = false;
        }, 5000);
    }

    close() {
        this.confirm = false;
    }

    ngOnInit(): void {
        this.service.confirm.subscribe((value: boolean) => {
            if (value) {
                this.showConfirm();
            }
        });
    }
}
