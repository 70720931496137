/* eslint-disable import/no-import-module-exports */
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import {
    AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import ModalComponent from 'app/shared/components/modal/modal.component';
import byteSize from 'byte-size';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { SetCorpus } from 'app/stores/actions/corpus/corpus.actions';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import ExportType from 'app/modules/CommonExportTypeEnum';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import download from 'app/utils/functions/download';
import CorpusService from 'app/utils/services/corpus.service';
import { GetCorpus } from '../../../../api/models';
import { CorpusService as CorpusApiService, FileService } from '../../../../api/services';
import SettingsState from '../../../../stores/state/settings/settings.state';

/**
 * Corpus files settings and status
 *
 * @export
 * @class CorpusSettingsFilesComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */

@Component({
    selector: 'app-corpus-settings-files',
    templateUrl: './corpus-settings-files.component.html',
    styleUrls: ['./corpus-settings-files.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsFilesComponent implements OnDestroy, AfterViewInit {
    public files: Array<any> = [];

    protected fileForTimeline: any;

    public estimated_duration: any;

    public columns: Array<any>;

    private loop: any;

    public id: number;

    public corpusType;

    selectedFile: any;

    private exportType = ExportType;

    private selectedType: ExportType;

    settingsState$: Observable<SettingsModel>;

    @ViewChild('statusRowTpl')
        statusRowTpl: TemplateRef<any>;

    @ViewChild('nblinesRowTpl')
        nblinesRowTpl: TemplateRef<any>;

    @ViewChild('sizeRowTpl')
        sizeRowTpl: TemplateRef<any>;

    @ViewChild('actionsRowTpl')
        actionsRowTpl: TemplateRef<any>;

    @ViewChild('dateRowTpl')
        dateRowTpl: TemplateRef<any>;

    @ViewChild('nameRowTpl', { read: TemplateRef }) nameRowTpl;

    @ViewChild('downloadModal')
    private downloadModal: TemplateRef<any>;

    @ViewChild('deleteModal')
    private deleteModal: TemplateRef<any>;

    modalRef: BsModalRef;

    deleteModalParams: any = {};

    fileStates: any[];

    filesInProgress: boolean = false;

    public hasAsyncTreatmentInProgress: boolean = false;

    public asReAnalyse: boolean = false;

    public showAlertOnProject: boolean = true;

    public showAlertErrorFileUpload: boolean = false;

    public showTimeline: boolean = false;

    public alertProject: string | null;

    public canUploadFile: boolean = false;

    private alertForFileIndex: number = -1;

    private lastTreatmentEnded: boolean = false;

    public mailHref: string;

    private mailTo!: string;

    public errorText: string = '';

    // BOOLEAN TO ALLOW TABLE HTML TO BE DISPLAYED
    displayTable: boolean = false;

    constructor(
        private corpus: CorpusApiService,
        private file: FileService,
        private store: Store,
        private translate: TranslateService,
        private modalService: BsModalService,
        private ngModalService: NgbModal,
        private http: HttpClient,
        private corpusUtils: CorpusService,
        private corpusService: CorpusService,
    ) {
        this.id = this.store.snapshot().corpus.corp_id;
        this.corpusType = Number.parseInt(this.store.snapshot().corpus.corp_type, 10);
    }

    ngAfterViewInit() {
        setTimeout(async () => {
            this.initColumns();
            this.settingsState$ = this.store.select(SettingsState);
            this.settingsState$.subscribe((s) => {
                this.setStateList(s.vcrm.file_states);
                this.mailTo = s.vcrm.mail_contact.mailto;
            });

            this.generateMailLink();

            await this.getFilesInfos();
            this.loop = setInterval(() => {
                this.getFilesInfos();
            }, 5000);

            this.displayTable = true;
        });
    }

    initColumns() {
        this.columns = [
            {
                prop: 'file_id',
                title: 'translations.corpus.settings.files.table.id',
            },
            {
                prop: 'file_name',
                title: 'translations.corpus.settings.files.table.fileName',
                cellTemplate: this.nameRowTpl,
            },
            {
                prop: 'file_date',
                title: 'translations.corpus.settings.files.table.fileDate',
                cellTemplate: this.dateRowTpl,
            },
            {
                prop: 'file_size',
                title: 'translations.corpus.settings.files.table.fileSize',
                cellTemplate: this.sizeRowTpl,
            },
            {
                prop: 'file_nb_analysed_lines',
                title: 'translations.corpus.settings.files.table.fileNbAnalysisLines',
                cellTemplate: this.nblinesRowTpl,
            },
            {
                prop: 'file_status_lbl',
                title: 'translations.corpus.settings.files.table.fileStatus',
                cellTemplate: this.statusRowTpl,
            },
            {
                title: '',
                cellTemplate: this.actionsRowTpl,
            },
        ];
    }

    private setStateList(state) {
        this.fileStates = [];
        state.forEach((f) => {
            if (f.status_id * 1 !== 10) {
                this.fileStates.push({
                    id: f.status_id,
                    text: f[this.translate.currentLang.toLowerCase()],
                });
            }
        });
    }

    /**
   * Launch an file analyse
   */
    reAnalysed(fileId) {
        this.estimated_duration = null;
        this.showAlertOnProject = false;
        this.showAlertErrorFileUpload = false;
        this.alertForFileIndex = this.store.snapshot().corpus.files.findIndex((f) => f.file_id === fileId);
        this.file.getV1FileFileIdAnalyseResponse(fileId).subscribe(
            () => {
                this.getFilesInfos();
            },
        );
    }

    reAnalysedAll() {
        this.corpus.getV1CorpusCorpIdAnalyse(this.id).subscribe(
            () => {
                this.getFilesInfos();
            },
        );
    }

    get canAccessData() {
        return this.fileForTimeline && this.fileForTimeline.status_flags ? this.fileForTimeline.status_flags.canAccessData : false;
    }

    async getFilesInfos() {
        try {
            const corpus:any = await this.corpus.getV1CorpusCorpIdStatus(this.id).toPromise();
            if (corpus) {
                this.asReAnalyse = false;
                this.hasAsyncTreatmentInProgress = corpus.status_flags.hasAsyncTreatmentInProgress;
                this.files = corpus.files.reverse();
                const currentFilesInProgress = this.files.filter((file) => {
                    if (file.status_flags.hasAnalyseNeeded) {
                        if (!this.reAnalysed) {
                            // Si on n'était pas en réanalyse avant on efface les précédents messages d'alertes
                            this.alertProject = null;
                        }
                        this.asReAnalyse = true;
                        this.lastTreatmentEnded = false;
                    }
                    if (file.status_flags.isAnalyseInProgress) {
                        return file;
                    }
                    return null;
                });

                if (currentFilesInProgress.length >= 1) {
                    // Si on commence un nouveau traitement, on regarde si on affiche la timeline ou non
                    if (!this.filesInProgress) {
                        this.showTimeline = currentFilesInProgress.length === 1;
                    }
                    // Si on affiche la timeline, on met à jour le fichier en cours de traitement
                    if (this.showTimeline) {
                        const [firstFileInProgress] = currentFilesInProgress;
                        this.fileForTimeline = firstFileInProgress;
                    }
                    this.lastTreatmentEnded = false;
                    this.showAlertOnProject = false;
                    this.showAlertErrorFileUpload = false;
                    // Only if estimated is not empty and estimated_duration not already calculted
                    if (corpus.corp_duration.estimated?.duration && !this.estimated_duration) {
                        // Calculate estimated date
                        const durationDate = new Date();
                        durationDate.setTime(durationDate.getTime() + (corpus.corp_duration.estimated.duration * 1000));
                        // If duration is more than 15 mn and duration time not ended on 0 or 5 minutes (ex 14h40 or 14h45)
                        if (corpus.corp_duration.estimated.duration > (15 * 60) && (durationDate.getMinutes() % 5 !== 0)) {
                            // we round time on next 5 mn
                            durationDate.setMinutes(Math.ceil(durationDate.getMinutes() / 5) * 5);
                        }
                        this.estimated_duration = formatDate(durationDate, 'HH:mm', 'fr-FR');
                    }
                    this.filesInProgress = true;
                } else {
                    if (this.filesInProgress) {
                        // Reload corpus when files are added to project
                        this.corpusService.loadCorpus(this.id);
                        this.lastTreatmentEnded = true;
                    }
                    // Si la timeline est affichée, on met à jour le fichier avec son dernier statut
                    if (this.showTimeline) {
                        this.fileForTimeline = this.files.find((f) => f.id === this.fileForTimeline.id);
                    }
                    this.filesInProgress = false;
                    this.estimated_duration = null;
                }
                this.updateAlertStatus(corpus);
                this.corpusUtils.currentCorpus.getValue().status_flags = { ...corpus.status_flags };
            }
        } catch (e) {
            this.estimated_duration = null;
        }

        // Update files on corpus in store
        const currentCorpus = this.store.snapshot().corpus;
        currentCorpus.files = this.files;
        this.store.dispatch(new SetCorpus(currentCorpus));
    }

    /**
   * Fired when CSV file is updated or Msurvey url / account is updated
   */
    async updateCsvOrMSurveyFile() {
        await this.getFilesInfos();
        this.showAlertOnProject = false;
        // Retreive first file because file array are reversed
        this.alertForFileIndex = 0;
    }

    ngOnDestroy(): void {
        clearInterval(this.loop);
        FavIconX.reset();
    }

    // eslint-disable-next-line class-methods-use-this
    byte(num): string {
        return byteSize(num, { units: 'metric_octet' });
    }

    openDownloadModal(file) {
        this.showAlertErrorFileUpload = false;
        this.selectedType = null;
        this.selectedFile = file;
        this.modalRef = this.modalService.show(this.downloadModal, {
            backdrop: 'static',
        });
    }

    openDeleteModal(file) {
        this.showAlertErrorFileUpload = false;
        this.selectedType = null;
        this.selectedFile = file;
        this.deleteModalParams = { file_id: this.selectedFile.file_id };
        this.modalRef = this.modalService.show(this.deleteModal, {
            class: 'modal-sm',
            backdrop: 'static',
        });
    }

    export() {
        this.http
            .get(
                `${this.file.rootUrl}/v1/file/${this.selectedFile.file_id}/download/${
                    this.selectedType
                }`,
                { responseType: 'blob', observe: 'response' },
            )
            .subscribe({
                next: (data) => {
                    download(window.URL.createObjectURL(data.body), data.headers.get('content-disposition').split('=')[1]);
                },
                error: (error) => {
                    setTimeout(() => {
                        if (error?.error?.error === '400104') {
                            const modal = this.ngModalService.open(ModalComponent, {});
                            modal.componentInstance.titleToTranslate = 'translations.corpus.settings.files.download.errors.fileNotFound.title';
                            modal.componentInstance.contentToTranslate = 'translations.corpus.settings.files.download.errors.fileNotFound.description';
                            modal.componentInstance.btnCloseToTranslate = 'translations.utils.ok';
                            modal.componentInstance.btnCloseVariant = 'primary';
                        } else if (error?.error?.error === '400105') {
                            const modal = this.ngModalService.open(ModalComponent, {});
                            modal.componentInstance.titleToTranslate = 'translations.corpus.settings.files.download.errors.fileInProgress.title';
                            modal.componentInstance.contentToTranslate = 'translations.corpus.settings.files.download.errors.fileInProgress.description';
                            modal.componentInstance.btnCloseToTranslate = 'translations.utils.ok';
                            modal.componentInstance.btnCloseVariant = 'primary';
                        } else {
                            const modal = this.ngModalService.open(ModalComponent, {});
                            modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                            modal.componentInstance.contentToTranslate = 'translations.corpus.settings.files.download.error';
                            modal.componentInstance.alertTypeVariant = 'danger';
                        }
                    }, 100);
                },
            });
    }

    deleteFile() {
        this.file.deleteV1FileFilenameIdFileid({
            fileid: this.selectedFile.file_id,
            filename: this.selectedFile.file_name,
        })
            .subscribe({
                next: () => {
                    this.showAlertOnProject = false;
                    this.lastTreatmentEnded = true;
                    this.alertForFileIndex = -1;
                    this.showTimeline = false;
                    this.fileForTimeline = null;
                    this.getFilesInfos();
                    // Reload corpus when files are deleted (update data like corpus date)
                    this.corpusService.loadCorpus(this.id);
                },
                error: (e) => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                    modal.componentInstance.contentToTranslate = `translations.httpErrors.${e?.error?.error || 'title'}`;
                    modal.componentInstance.alertTypeVariant = 'danger';
                },
            });
    }

    closeModal() {
        this.modalRef.hide();
    }

    /**
   * Return the mailto: link
   */
    generateMailLink(): void {
        this.mailHref = `mailto:${this.mailTo
        }?subject=${this.translate.instant('translations.utils.help.mail.subject', { name: this.store.snapshot().corpus.corp_name })
        }&body=${encodeURIComponent(this.translate.instant('translations.utils.help.mail.body', { url: `${window.location.origin}/project/${this.id}/settings/files` }))}`;
    }

    /**
   * Update alert box on this page (show alert on top of the page or on the top of the table)
   * Determine if alert is error / warning or success
   */
    updateAlertStatus(corpus: GetCorpus) {
        let status = null;
        // On désactive l'upload d'un nouveau fichier si le projet n'autorise pas l'upload
        this.canUploadFile = corpus.status_flags.canUpload;
        // Si on a un ou plusieurs fichiers en cours, on n'affiche pas d'alerte (c'est l'alert in progress qui sera affiché grace à la variable this.filesInProgress)
        if (this.filesInProgress) {
            status = null;
        } else {
            const nbFileOnError = corpus.files.filter((file) => file.file_error).length;
            // Si on doit s'occuper d'un fichier en particulier (dernier fichier uploadé ou fichier reanalysé)
            if (this.alertForFileIndex !== -1) {
                const file = corpus.files[this.alertForFileIndex];
                // Si le dernier fichier est en erreur, alerte d'erreur
                if (file.file_error) {
                    status = 'error';
                } else {
                    // Si le dernier fichier n'est pas en erreur, alors si le projet contient au moins un fichier en erreur => warning sinon success
                    status = (nbFileOnError > 0) ? 'warning' : 'success';
                }
            } else {
                // Sinon on regarde le status de tous les fichiers du projet
                // eslint-disable-next-line no-lonely-if
                if (nbFileOnError === corpus.files.length) {
                    // Si tous les fichiers du projet sont en erreur, alors on affiche une alerte d'erreur
                    status = 'error';
                } else if (nbFileOnError > 0) {
                    // Sinon si le projet contient au moins une erreur, on affiche une alerte de warning
                    status = 'warning';
                } else if (this.lastTreatmentEnded) {
                    // Sinon si le dernier traitement est terminé et qu'il n'y a pas d'erreur, on affiche un message de success
                    status = 'success';
                }
            }
        }
        this.alertProject = status;
    }

    /**
     * Retourne la classe (succes/info/danger) à mettre sur chaque status du fichier demandé
     */
    getStateFile(file, index) {
        // Si le fichier n'est pas en erreur et pas en analyse, les status sont forcément ok
        if (!file.status_flags.isOnError && !file.status_flags.isAnalyseInProgress) {
            return 'text-color-success';
        }
        // sinon on récupère l'index du status en cours du fichier
        const fileStateIndex = this.fileStates.findIndex((s) => Number(file.file_status_next) === s.id);
        if (index < fileStateIndex) {
            return 'text-color-success';
        }
        if (index === fileStateIndex) {
            return (file.status_flags.isOnError) ? 'text-color-danger' : 'text-color-info';
        }
        return '';
    }
}
