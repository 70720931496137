import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import BurgerMenuComponent from './components/burger-menu/burger-menu.component';
import CorpusTableSelectComponent from './components/corpus-table-select/corpus-table-select.component';
import FreeSearchComponent from './components/free-search/free-search.component';
import LoginMSurveyComponent from './components/login-msurvey/login-msurvey.component';
import MypaginationComponent from './components/mypagination/mypagination.component';
import NewsDisplayerComponent from './components/news-displayer/news-displayer.component';
import NewsItemComponent from './components/news-item/news-item.component';
import NewsHeadlineComponent from './components/news-headline/news-headline.component';
import TableComponent from './components/table/table.component';
import ThematicsListComponent from './components/thematics-list/thematics-list.component';
import TimelineComponent from './components/timeline/timeline.component';
import GtmDirective from './directives/gtm.directive';
import RegExpDirective from './directives/regexp.directive';
import FileSizePipe from './pipes/file-size.pipe';
import PluralizePipe from './pipes/pluralize.pipe';
import TruncatePipe from './pipes/truncate.pipe';
import DashboardScrollDirective from './directives/dashboard-scroll.directive';
import IndeterminateDirective from './directives/indeterminate.directive';
import CookiesConsentComponent from './components/footer/cookies-consent/cookies-consent.component';
import CookiesConsentService from './components/footer/cookies-consent/cookies-consent.service';
import CookiesComponent from './components/footer/cookies/cookies.component';
import FooterComponent from './components/footer/footer.component';
import PrivacyPolicyComponent from './components/footer/privacy-policy/privacy-policy.component';
import TermOfUseComponent from './components/footer/term-of-use/term-of-use.component';
import HeaderComponent from './components/header/header.component';
import CorpusSelectComponent from './components/header/corpus-select/corpus-select.component';
import PercentColorDirective from './directives/percent-color.directive';
import BaseChartDirective from './directives/base-chart.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PopoverModule.forRoot(),
        TranslateModule.forChild(),
        PaginationModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        FormsModule,
        TooltipModule.forRoot(),
        NgbCollapseModule,
        NgbDropdownModule,
    ],
    declarations: [
        BurgerMenuComponent,
        CorpusTableSelectComponent,
        PluralizePipe,
        TruncatePipe,
        FileSizePipe,
        TableComponent,
        BaseChartDirective,
        GtmDirective,
        RegExpDirective,
        FreeSearchComponent,
        ThematicsListComponent,
        TimelineComponent,
        MypaginationComponent,
        NewsItemComponent,
        NewsDisplayerComponent,
        NewsHeadlineComponent,
        LoginMSurveyComponent,
        IndeterminateDirective,
        FooterComponent,
        CookiesComponent,
        TermOfUseComponent,
        PrivacyPolicyComponent,
        CookiesConsentComponent,
        HeaderComponent,
        CorpusSelectComponent,
        PercentColorDirective,
        DashboardScrollDirective,
    ],
    exports: [
        BurgerMenuComponent,
        TranslateModule,
        CorpusTableSelectComponent,
        PluralizePipe,
        TruncatePipe,
        FileSizePipe,
        TableComponent,
        NgxPermissionsModule,
        BaseChartDirective,
        GtmDirective,
        RegExpDirective,
        PercentColorDirective,
        FreeSearchComponent,
        MypaginationComponent,
        ThematicsListComponent,
        TimelineComponent,
        NewsItemComponent,
        NewsDisplayerComponent,
        NewsHeadlineComponent,
        LoginMSurveyComponent,
        FooterComponent,
        HeaderComponent,
        DashboardScrollDirective,
    ],
    providers: [
        CookiesConsentService,
    ],
})
export default class SharedModule {}
