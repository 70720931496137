import {
    Component, EventEmitter, Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import {
    ExplorationLLM, LLMStatus, ThemeLLM,
} from 'app/api/models/llm';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { LlmProfileConfig } from 'app/api/models/dashboard-config';
import { Yaxis } from 'app/api/models/histoparams';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import { Store } from '@ngxs/store';
import CorpusService from 'app/utils/services/corpus.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { CorpusService as CorpusApi } from 'app/api/services';
import { HttpClient } from '@angular/common/http';
import ModalComponent from 'app/shared/components/modal/modal.component';
import download from 'app/utils/functions/download';
import { LegendItem } from '../../../graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import LlmContextBarChartConfigurationPopupComponent from '../llm-context-bar-chart-configuration-popup/llm-context-bar-chart-configuration-popup.component';

@Component({
    selector: 'app-llm-context',
    templateUrl: './llm-context.component.html',
    styleUrls: ['./llm-context.component.scss'],
})
export default class LlmContextComponent extends BaseComponent implements OnInit, OnChanges {
    modalRef: NgbModalRef;

    chartData;

    filteredChartData;

    options;

    legendItems: Array<LegendItem> = [];

    allThemes: ThemeLLM[] = [];

    viewThemeInDetail: ThemeLLM;

    isDiscoveryVisible = true;

    isDiscoveryEditing = false;

    discoveryFiltersDifferent: string[] = [];

    discoveryFilesDifferent: { status: 'import' | 'remove', nb: number }[] = [];

    discoveryName = '';

    isPptFlashReportInProgress :boolean = false;

    @Input()
        explorationLLM: ExplorationLLM;

    @Input()
        dashboardIsModified: boolean;

    @Output()
        updateDiscovery: EventEmitter<string> = new EventEmitter();

    @Output()
        refreshDiscovery: EventEmitter<void> = new EventEmitter();

    @Output()
        reloadDiscovery: EventEmitter<void> = new EventEmitter();

    @Output()
        deleteDiscovery: EventEmitter<void> = new EventEmitter();

    @ViewChild('chart')
        chart: BaseChartDirective;

    @ViewChild('saveBeforePPTExport')
    private saveBeforePPTExport:TemplateRef<any>;

    get formattedDate() {
        return moment(this.explorationLLM.llm_creation_date).format('L');
    }

    // Détermine si aucun thème n'est sélectionné
    get notThemeSelected() {
        return this.allThemes.filter((t) => t.selected).length === 0;
    }

    // Détermine si on peut modifier la découverte ou non. Seulement si on est le créateur (propriétaire) du dashboard
    get canModifyDiscovery() {
        return this.dashboardService.currentDashboard.getValue().username_creator === this.store.snapshot().user.username;
    }

    constructor(
        private store: Store,
        public modalService: NgbModal,
        private translate: TranslateService,
        protected translateService: TranslateService,
        private manageDashboardService: ManageDashboardService,
        private dashboardService: DashboardService,
        private corpusService: CorpusService,
        private graphService: GraphService,
        private corpusApi: CorpusApi,
        private http: HttpClient,
    ) {
        super();
        moment.locale(this.translate.currentLang);
    }

    ngOnInit() {
        // Met à jour la comparaison des filtres entre le dashboard et la découverte lorsque l'on sauvegarde un dashboard
        this.subs.sink = this.manageDashboardService.dashboardSaved.subscribe(() => {
            this.compareDiscoveryFilters();
        });

        // Compare les fichiers du corpus et ceux de la découverte
        if (this.explorationLLM.llm_status === LLMStatus.DONE) {
            const discoveryFilesId = Object.keys(JSON.parse(this.explorationLLM.llm_info_dash_files)).map((fileId) => (parseInt(fileId, 10)));
            const corpusFilesId = this.corpusService.currentCorpus.getValue().files.map((file) => file.file_id);
            const importedFiles = corpusFilesId.filter((fileId) => !discoveryFilesId.includes(fileId));
            if (importedFiles.length) {
                this.discoveryFilesDifferent.push({ status: 'import', nb: importedFiles.length });
            }
            const removedFiles = discoveryFilesId.filter((fileId) => !corpusFilesId.includes(fileId));
            if (removedFiles.length) {
                this.discoveryFilesDifferent.push({ status: 'remove', nb: removedFiles.length });
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.explorationLLM?.currentValue) {
            this.discoveryName = this.explorationLLM.llm_name;
            if (this.explorationLLM.llm_status === LLMStatus.DONE) {
                this.compareDiscoveryFilters();
                this.options = this.getChartOptions();
                this.chartData = this.getChartData();
                this.onToggleLegend();
                this.allThemes = _.cloneDeep(this.explorationLLM.themes);
                this.loadDiscoveryConfig();
            }
        }
    }

    /**
     * Compare les filtres du dashboard et ceux de la découverte
     */
    compareDiscoveryFilters() {
        const diff = this.manageDashboardService.compareTwoFilters(JSON.parse(this.explorationLLM.llm_info_dash_json_params), this.dashboardService.currentDashboard.getValue().dash_json_params);
        this.discoveryFiltersDifferent = Object.keys(diff).map((key) => (diff[key] ? key : null)).filter((e) => e);
    }

    get config() {
        return this.dashboardService.getLlmProfileConfig(this.explorationLLM.llm_id);
    }

    updateConfig() {
        this.dashboardService.setLlmProfileConfig(this.explorationLLM.llm_id, this.config);
    }

    get canExportPpt() {
        return !this.isPptFlashReportInProgress // s'il n'y a pas d'export en cours
        && this.explorationLLM.llm_status !== 1 // si l'analyse n'est pas en cours
        && this.explorationLLM.llm_status !== 3 // si l'analyse n'est pas en erreur;
    }

    exportPpt() {
        if (this.canExportPpt) {
            // si le dahboard n'est pas sauvegardé, il faut le sauvegarder avant
            if (this.dashboardService.hasConfigurationNotSaved()) {
                this.modalRef = this.modalService.open(this.saveBeforePPTExport, {
                    backdrop: 'static',
                });
            } else {
                this.downloadPPTFlashReportFromBackend();
            }
        }
    }

    /**
     * Generate (backend side) and then trigger in the browser the download of a PowerPoint PPTX "flash report" for a
     * particular dashboard id
     * @param dash_id dashboard id
     * @param type type de rapport à télécharger (normal or specific)
     * @return nothing
     */
    downloadPPTFlashReportFromBackend(): void {
        // s'il y a déjà un export ppt en cours, on ne fait rien
        if (this.isPptFlashReportInProgress) {
            return;
        }

        const url = `${this.corpusApi.rootUrl}/v1/dashboard/${this.explorationLLM.dash_id}/flashreport/model4/${this.explorationLLM.llm_id}`;
        this.isPptFlashReportInProgress = true;
        this.http
            .get(url, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (data) => {
                    this.isPptFlashReportInProgress = false;
                    download(URL.createObjectURL(data.body), data.headers.get('content-disposition').split('=')[1], '_blank');
                },
                error: (errorObject) => {
                    this.isPptFlashReportInProgress = false;

                    // Javascript proxy handler customization. Is trigggered when errorObject is updated with new information.
                    errorObject.proxyHandler.set = function (
                        target,
                        key,
                        value,
                    ) {
                        if (value.error === '401015') {
                            this.manageDashboardService.currentDashboardService.displayServerError(value);
                        } else {
                            const modal = this.modalService.open(ModalComponent, {});
                            modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                            modal.componentInstance.contentToTranslate = `translations.httpErrors.${errorObject?.error?.error || 'title'}`;
                            modal.componentInstance.alertTypeVariant = 'danger';
                            this.isPptFlashReportReady = false;
                        }
                    }.bind(this);
                },
            });
    }

    getChartOptions() {
        const { yaxis } = this.config;

        return {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                labels: false,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.graphService.customTooltips(yaxis === Yaxis.percent ? '%' : ''),
                },
            },
            legend: {
                display: false,
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        min: 0,
                        color: '#000',
                        callback(value) {
                            return `${value}${yaxis === Yaxis.percent ? '%' : ''}`;
                        },
                    },
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                },
            },
        };
    }

    /**
     * Methode qui contruit les graphiques histogrammes. Génère les données et les options du graphique
     */
    getChartData() {
        const datasets = {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
        };

        const labels = [];

        this.explorationLLM.themes.forEach((theme) => {
            const valueName = theme.name;
            theme.percent_verbatim = `${Math.round(parseFloat(theme.percent_verbatim) * 10) / 10}`;
            const color = this.manageDashboardService.getDataColor(`llmcontext-${this.explorationLLM.llm_discovery_config_id}`, valueName);
            if (labels.indexOf(valueName) < 0) {
                labels.push(valueName);
                this.legendItems.push({
                    color,
                    displayName: valueName,
                    inputName: valueName,
                    selected: !this.config.hidden_themes_graph.includes(valueName),
                });
            }

            if (this.config.yaxis === Yaxis.percent) {
                datasets.data.push(theme.percent_verbatim !== null ? theme.percent_verbatim : null);
            } else {
                datasets.data.push(theme.nb_verbatim !== null ? theme.nb_verbatim : null);
            }

            datasets.backgroundColor.push(color);
            datasets.hoverBackgroundColor.push(color);
        });

        return {
            datasets: [datasets],
            labels,
        };
    }

    onToggleDiscoveryVisibility() {
        this.isDiscoveryVisible = !this.isDiscoveryVisible;
        this.config.hidden_profile = !this.isDiscoveryVisible;
        this.updateConfig();
    }

    onToggleLegend() {
        if (this.chartData && this.chartData.datasets.length) {
            this.filteredChartData = {
                labels: this.chartData.labels.filter((d, i) => this.legendItems[i].selected === true),
                datasets: [{
                    data: this.chartData.datasets[0].data.filter((d, i) => this.legendItems[i].selected === true),
                    backgroundColor: this.chartData.datasets[0].backgroundColor.filter((d, i) => this.legendItems[i].selected === true),
                    hoverBackgroundColor: this.chartData.datasets[0].hoverBackgroundColor.filter((d, i) => this.legendItems[i].selected === true),
                }],
            };

            this.config.hidden_themes_graph = this.legendItems.filter((item) => !item.selected).map((item) => item.inputName);
            this.updateConfig();
        }
    }

    get modifiedFilesCount() {
        return this.discoveryFilesDifferent.reduce((acc, file) => acc + file.nb, 0);
    }

    /**
     * Ouvre le thème sélectionné en grand (pour voir plus d'infos)
     */
    onOpenDetailTheme(theme: ThemeLLM) {
        this.viewThemeInDetail = theme;

        this.config.selected_theme_details = theme.name;
        this.updateConfig();

        document.getElementById(`viewThemeContainer_${this.explorationLLM.llm_id}`).scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Annule les modifications sur le nom de la découverte
     */
    onCancelDiscoveryName() {
        this.discoveryName = this.explorationLLM.llm_name;
        this.isDiscoveryEditing = false;
    }

    /**
     * Valide les modifications sur le nom de la découverte
     */
    onValidDiscoveryName() {
        this.isDiscoveryEditing = false;
        this.updateDiscovery.emit(this.discoveryName);
    }

    /**
     * Lorsque l'on change la sélection d'un thème
     */
    onToggleTheme(theme: ThemeLLM) {
        if (!theme.selected) {
            this.config.hidden_themes_cards.push(theme.name);
        } else {
            this.config.hidden_themes_cards = this.config.hidden_themes_cards.filter((t) => t !== theme.name);
        }

        // si le thème vient d'être décocher et que c'était celui qui était afficher en détail, on le masque
        if (theme.name === this.viewThemeInDetail?.name && !theme.selected) {
            this.viewThemeInDetail = null;
            this.config.selected_theme_details = null;
        }

        this.updateConfig();
    }

    /**
     * Affiche tous les thèmes
     */
    onDisplayAllThemes() {
        this.allThemes.forEach((t) => {
            t.selected = true;
            this.config.hidden_themes_cards = [];
        });
        this.updateConfig();
    }

    loadDiscoveryConfig() {
        this.allThemes.forEach((t) => { t.selected = !this.config.hidden_themes_cards.includes(t.name); });
        this.viewThemeInDetail = this.allThemes.find((t) => t.name === this.config.selected_theme_details);
        this.isDiscoveryVisible = !this.config.hidden_profile;
    }

    openSettings() {
        const modal = this.modalService.open(LlmContextBarChartConfigurationPopupComponent);
        modal.componentInstance.params = this.config;

        modal.componentInstance.save.subscribe((params: LlmProfileConfig) => {
            this.dashboardService.setLlmProfileConfig(this.explorationLLM.llm_id, params);

            this.refreshDiscovery.emit();
        });
    }
}
