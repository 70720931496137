/* eslint-disable class-methods-use-this */
import { TranslateService } from '@ngx-translate/core';
import { State, Action, StateContext } from '@ngxs/store';
import MsurveyService from 'app/utils/services/msurvey.service';
import { HttpClient } from '@angular/common/http';
import { Files } from 'app/api/models/files';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Injectable } from '@angular/core';
import { NewCorpusModel } from '../../models/new-corpus/new-corpus.model';
import {
    SetColMessage,
    SetColDate,
    SetFilters,
    SetFileFirstLines,
    SetName,
    AddFile,
    SetEtapes,
    SetFileColsTypes,
    ResetNewCorpus,
    FinishNewCorpus,
    SetNewCorpus,
    SetIndexStopwords,
    AddMSurveyFile,
    SetColScore,
} from '../../actions/new-corpus/new-corpus.actions';

/**
 * RGXS Store for new corpus stage
 *
 * @export
 * @class NewCorpusState
 */
@Injectable()
@State<NewCorpusModel>({
    name: 'newcorpus',
    defaults: {
        datas: {},
        etapes: [
            {
                title: 'translations.newcorpus.stage1.title',
                status: 'active',
                id: 0,
            },
            {
                title: 'translations.newcorpus.stage2.title',
                status: '',
                id: 1,
            },
            {
                title: 'translations.newcorpus.stage3.title',
                status: '',
                id: 2,
            },
            {
                title: 'translations.newcorpus.stage4.title',
                status: '',
                id: 3,
            },
            {
                title: 'translations.newcorpus.stage5.title',
                status: '',
                id: 4,
            },
            {
                title: 'translations.newcorpus.stage6.title',
                status: '',
                id: 5,
            },
        ],
        finish: false,
    },
})
export default class NewCorpusState {
    constructor(
        private http: HttpClient,
        private msurvey: MsurveyService,
        private spinner: SpinnerVisibilityService,
        private translate: TranslateService,
    ) {}

    @Action(SetNewCorpus)
    SetNewCorpus(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetNewCorpus,
    ) {
        const state = getState();
        state.datas = { ...state.datas, ...payload };
        setState(state);
    }

    @Action(SetName)
    setName(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetName,
    ) {
        const state = getState();
        state.datas.name = payload.name;
        state.datas.description = payload.desc;
        setState({
            ...state,
        });
    }

    @Action(ResetNewCorpus)
    ResetNewCorpus({ getState, setState }: StateContext<NewCorpusModel>) {
        const state = getState();
        state.datas = {};
        state.finish = false;
        setState({
            ...state,
        });
    }

    @Action(FinishNewCorpus)
    FinishNewCorpus(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: FinishNewCorpus,
    ) {
        const state = getState();
        state.finish = true;
        state.datas.id = payload.id;
        setState({
            ...state,
        });
    }

    @Action(SetColMessage)
    SetColMessageName(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetColMessage,
    ) {
        const state = getState();
        state.datas.verbatim = payload;
        setState({
            ...state,
        });
    }

    @Action(SetColScore)
    SetColScoreName(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetColScore,
    ) {
        const state = getState();
        state.datas.colScore = payload;
        setState({
            ...state,
        });
    }

    @Action(SetColDate)
    SetColDate(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetColDate,
    ) {
        const state = getState();
        state.datas.dateFormat = payload.dateFormat;
        state.datas.date = payload.date;
        state.datas.noColDate = payload.noColDate;
        state.datas.dateInit = payload.dateInit;
        setState({
            ...state,
        });
    }

    @Action(SetFilters)
    SetFilters(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetFilters,
    ) {
        const state = getState();
        state.datas.filters = payload;
        setState({
            ...state,
        });
    }

    @Action(SetFileFirstLines)
    SetFileFirstLines(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetFileFirstLines,
    ) {
        const state = getState();
        state.datas.fileFirstLines = payload;
        setState({
            ...state,
        });
    }

    @Action(SetFileColsTypes)
    SetFileColsTypes(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetFileColsTypes,
    ) {
        const state = getState();
        state.datas.colsTypes = payload;
        setState({
            ...state,
        });
    }

    @Action(AddFile)
    addFile(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: AddFile,
    ) {
        const state = getState();
        state.datas.file = payload;
        setState({
            ...state,
        });
    }

    @Action(AddMSurveyFile)
    async addMSurveyFile(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: any,
    ) {
        this.spinner.show();
        try {
            // delete existing file
            await this.msurvey.deleteExistingFile().toPromise();
            if (payload.corpusType === 2 && payload.id && payload.accountInfo) {
                // call backend to upload msurvey from ID
                await this.msurvey.createFromId(payload.id, payload.accountInfo).toPromise();
            } else if (payload.corpusType === 3 && payload.url) {
                // call backend to download msurvey from URL
                await this.msurvey.createFromURL(payload.url).toPromise();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw { error: { message: this.translate.instant('translations.newcorpus.stage2.errorMsurvey') } };
            }
            // retreive file name
            const data = await this.http.get('/api/v1/file', { withCredentials: true }).toPromise();
            // save filename and corpusType on store
            const state = getState();
            state.datas.file = {
                name: (data as Files).files[0].file_name,
                corpusType: payload.corpusType,
            };
            setState({
                ...state,
            });
            this.spinner.hide();
        } catch (error) {
            this.spinner.hide();
            throw error;
        }
    }

    @Action(SetEtapes)
    SetEtapes(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetEtapes,
    ) {
        const state = getState();
        state.etapes.map((et) => {
            const etape: any = et;
            if (etape.id < payload) {
                etape.status = 'done';
            } else if (etape.id > payload) {
                etape.status = 'next';
            } else {
                etape.status = 'current';
            }
            return etape;
        });

        state.finish = (state.etapes[(state.etapes.length - 1)] as any).status === 'done';
        setState({
            ...state,
        });
    }

    @Action(SetIndexStopwords)
    SetIndexStopwords(
        { getState, setState }: StateContext<NewCorpusModel>,
        { payload }: SetIndexStopwords,
    ) {
        const state = getState();
        state.datas.indexStopwords = payload;
        setState({
            ...state,
        });
    }
}
