import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import DashboardTabs from 'app/utils/models/dashboard-tabs';

@Component({
    selector: 'app-nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrls: ['./nav-tabs.component.scss'],
})
export default class NavTabsComponent {
    @Input()
        activeTab: string;

    @Input()
        hasClassification: boolean;

    @Output()
        changeTab: EventEmitter<string> = new EventEmitter();

    get tabsList(): Array<string> {
        return [
            DashboardTabs.EXPLORE_VERBATIM,
            this.hasClassification ? DashboardTabs.CLASSIFICATION_AUTO : DashboardTabs.EVOLUTION_TEMPORELLE,
            DashboardTabs.THEMATICS_DATA,
            DashboardTabs.SATISFACTION,
            !this.manageDashboardService.isDashboardComparison ? DashboardTabs.LABLLM : null,
            DashboardTabs.HEATMAP,
            DashboardTabs.ASSOCIATED_DATA,
        ].filter((e) => e);
    }

    constructor(
        private manageDashboardService: ManageDashboardService,
    ) {
    }

    /**
     * Fired when user click on nav tab : Scroll to this tab content
     */
    scrollTo(tab) {
        if (document.getElementById(`${tab}Title`)) {
            this.changeTab.emit(`${tab}Title`);
        }
    }
}
