import {
    Component, EventEmitter, Input,
    Output,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import { ThemeLLM } from 'app/api/models/llm';
import { ThematicService } from 'app/api/services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import ThematicEditorComponent from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { Store } from '@ngxs/store';
import { Thematic } from 'app/api/models/thematic';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';

@Component({
    selector: 'app-llm-card-theme',
    templateUrl: './llm-card-theme.component.html',
    styleUrls: ['./llm-card-theme.component.scss'],
})
export default class LlmThemeCardComponent extends BaseComponent {
    modalRef: NgbModalRef;

    @Input()
        theme: ThemeLLM;

    @Input()
        showAllInfos: boolean;

    @Output()
        selectTheme: EventEmitter<ThemeLLM> = new EventEmitter();

    constructor(
        private store: Store,
        private dashboardService: DashboardService,
        public modalService: NgbModal,
        protected translateService: TranslateService,
        private thematicService: ThematicService,
    ) {
        super();
    }

    /**
     * Evènement lorsque l'utilisateur clique sur le bouton de création d'une thématique
     */
    onCreateThematic() {
        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.initValue = {
            name: this.theme.name,
            definition: [this.theme.rule],
            lang: this.store.snapshot().corpus.corp_lang,
        };
        modal.componentInstance.allowSameValue = true;

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();
            this.thematicService.postV1Thematic_1(thematic).subscribe((thId) => {
                const newThematics = {
                    selected_all: false,
                    selected_not: false,
                    thematics: [{
                        them_name: thematic.name,
                        them_type: 'rule',
                        them_definition: thematic.definition,
                        lang: thematic.lang,
                        selected: true,
                        them_id: thId.id,
                        perso: true,
                        type_sharing: '0',
                    }],
                };

                this.dashboardService.currentFilters.getValue().selected_all = false;
                this.dashboardService.currentFilters.getValue().selected_not = false;
                this.dashboardService.currentFilters.getValue().thematics = newThematics.thematics.concat(this.dashboardService.currentFilters.getValue().thematics || []);
                this.dashboardService.updateThematicList(this.dashboardService.currentFilters.getValue());
            });
        });
    }
}
