import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import ModalComponent from 'app/shared/components/modal/modal.component';
import * as moment from 'moment';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import CorpusService from '../../../api/services/corpus.service';
import { FinishNewCorpus, SetEtapes } from '../../../stores/actions/new-corpus/new-corpus.actions';
import BaseEtapeComponent from '../BaseEtapeComponent';

/**
 * Last stage new corpus
 *
 * @export
 * @class Etape6confirmComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape6confirm',
    templateUrl: './etape6confirm.component.html',
    styleUrls: ['./etape6confirm.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape6confirmComponent extends BaseEtapeComponent implements OnInit {
    storeDatas: any;

    projectDatas: any = {};

    corpusId = null;

    public isUpload: boolean = false;

    constructor(
        private store: Store,
        private router: Router,
        private modalService: NgbModal,
        private corpusService: CorpusService,
    ) {
        super();

        const state = this.store.snapshot().newcorpus;
        if (this.isEtape1Invalid(state)) {
            this.router.navigate(['new-project']);
        } else if (this.isEtape2Invalid(state)) {
            this.router.navigate(['new-project', 'upload']);
        } else if (this.isEtape3Invalid(state)) {
            this.router.navigate(['new-project', 'date']);
        } else if (this.isEtape4Invalid(state)) {
            this.router.navigate(['new-project', 'message']);
        } else {
            this.storeDatas = state.datas;
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.store.dispatch(new SetEtapes(5));
            this.prepareProject();
        }, 500);
    }

    createProject() {
        this.isUpload = true;
        this.prepareProject();

        this.corpusService.postV1Corpus(this.projectDatas).subscribe({
            next: (data) => {
                this.corpusId = data.id;
                this.store.dispatch(new FinishNewCorpus({ id: this.corpusId })).subscribe(() => {
                    this.router.navigate(['new-project', 'feedback']);
                });
            },
            error: (err) => {
                const modal = this.modalService.open(ModalComponent, {});
                modal.componentInstance.titleToTranslate = 'translations.newcorpus.stage6.error';
                modal.componentInstance.contentToTranslate = `translations.httpErrors.${err?.error?.error || 'title'}`;
                modal.componentInstance.alertTypeVariant = 'danger';
            },
            complete: () => {
                this.isUpload = false;
            },
        });
    }

    prepareProject() {
        this.projectDatas.dateFormat = this.storeDatas.dateFormat;
        if (this.storeDatas.noColDate) {
            this.projectDatas.dateInit = moment(this.storeDatas.dateInit).format('YYYY-MM-DD');
        } else {
            this.projectDatas.dateField = this.storeDatas.date;
        }

        const verbatimArray: Array<{ name: any, displayName: any, lang: any, mode_id: any }> = [];
        this.projectDatas.name = this.storeDatas.name;
        this.projectDatas.filename = this.storeDatas.file.name;
        this.projectDatas.delimiter = this.storeDatas.file.delimiter;
        this.projectDatas.description = this.storeDatas.description;

        this.storeDatas.verbatim.forEach((item) => {
            const verbatim = {
                name: item.name, displayName: item.displayName, lang: item.lang, mode_id: item.mode_id,
            };
            verbatimArray.push(verbatim);
        });
        this.projectDatas.verbatimFields = verbatimArray;
        this.projectDatas.verbatimLang = this.storeDatas.language;

        this.projectDatas.fields = this.storeDatas.filters.concat(this.storeDatas.colScore);
        this.projectDatas.fields = this.projectDatas.fields.sort((a, b) => {
            if (a.inputName.toUpperCase() > b.inputName.toUpperCase()) {
                return 1;
            }
            if (a.inputName.toUpperCase() < b.inputName.toUpperCase()) {
                return -1;
            }
            return 0;
        });
        this.projectDatas.indexStopwords = this.storeDatas.indexStopwords;
    }
}
